import AdminGate from '@navigation/admin-gate'
import EmailGate from '@navigation/email-gate'
import { RoutesName } from '@navigation/routes-name'
import Activities from '@screens/activities'
import ActivitiesType from '@screens/activities-type'
import Activity from '@screens/activity-completion/activity'
import AlreadyCompleted from '@screens/activity-completion/already-completed'
import Badges from '@screens/activity-completion/badges'
import End from '@screens/activity-completion/end'
import Informations from '@screens/activity-completion/informations'
import Participants from '@screens/activity-completion/participants'
import ActivityCreate from '@screens/activity-create'
import ActivityEdit from '@screens/activity-edit'
import BadgesScreen from '@screens/badges/badges'
import NotFound from '@screens/not-found'
import ParticipantsScreen from '@screens/participants'
import UserCreate from '@screens/user-create'
import UserEdit from '@screens/user-edit'
import Users from '@screens/users'
import { Route, Routes } from 'react-router-dom'

const Navigation = () => (
  <Routes>
    <Route element={<AdminGate />} path={RoutesName.HOME}>
      <Route element={<Activities />} path={RoutesName.ACTIVITIES} />

      <Route element={<ActivitiesType />} path={RoutesName.ACTIVITIES_TYPE} />

      <Route element={<ActivityCreate />} path={RoutesName.ACTIVITY_CREATE} />

      <Route element={<ActivityEdit />} path={RoutesName.ACTIVITY_EDIT} />

      <Route element={<BadgesScreen />} path={RoutesName.BADGES} />

      <Route element={<ParticipantsScreen />} path={RoutesName.PARTICIPANTS} />

      <Route element={<Users />} path={RoutesName.USERS} />

      <Route element={<UserCreate />} path={RoutesName.USER_CREATE} />

      <Route element={<UserEdit />} path={RoutesName.USER_EDIT} />
    </Route>

    <Route element={<EmailGate />}>
      <Route element={<Informations />} path={RoutesName.ACTIVITY_COMPLETION_INFORMATIONS} />

      <Route element={<Activity />} path={RoutesName.ACTIVITY_COMPLETION_ACTIVITY} />

      <Route element={<Participants />} path={RoutesName.ACTIVITY_COMPLETION_PARTICIPANTS} />

      <Route element={<Badges />} path={RoutesName.ACTIVITY_COMPLETION_BADGES} />

      <Route element={<End />} path={RoutesName.ACTIVITY_COMPLETION_END} />

      <Route element={<AlreadyCompleted />} path={RoutesName.ACTIVITY_COMPLETION_COMPLETED} />
    </Route>

    <Route element={<NotFound />} path='*' />
  </Routes>
)

export default Navigation
