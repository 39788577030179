import ErrorPage from '@components/error-page'
import Layout from '@components/layout/admin/admin'
import FullPageLoading from '@components/loading/full-page-loading'
import { useLoadData } from '@services/helpers/load-data'
import { stringifyError } from '@services/utils/error-utils'
import { Outlet } from 'react-router-dom'

const AdminGate = () => {
  const { isLoading, error } = useLoadData()

  if (isLoading) {
    return <FullPageLoading text={'Chargement des données'} />
  }

  if (error) {
    return (
      <ErrorPage
        message={stringifyError(error)}
        title={'Erreur lors du chargement des données'}
      />
    )
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export default AdminGate
