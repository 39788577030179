import Button from '@components/button/button'
import { ButtonProps } from '@components/button/button.interfaces'
import classNames from 'classnames'
import { forwardRef } from 'react'

const SuccessButton = ({ className, ...props }: ButtonProps, ref) => {
  return (
    <Button
      {...props}
      className={classNames(
        'bg-green-600 text-white hover:bg-green-700',
        className
      )}
      ref={ref}
    />
  )
}

export default forwardRef<HTMLButtonElement, ButtonProps>(SuccessButton)
