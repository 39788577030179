import { Activity } from '@interfaces/api/activity'
import { FC } from 'react'

interface ActivityCardProps {
  activity: Activity
  onClick: () => void
}

const ActivityCard: FC<ActivityCardProps> = ({ activity, onClick }) => {
  return <div
    aria-hidden='true'
    className='bg-white w-full rounded-md shadow cursor-pointer'
    onClick={onClick}
  >
    <img
      alt='Activity'
      className='w-full h-36 object-cover rounded-t-md'
      src={activity?.cover?.url ?? '/assets/activity-placeholder.png'}
    />

    <div className='p-3'>
      <p>{activity.title}</p>
    </div>
  </div>
}

export default ActivityCard
