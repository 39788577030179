import { ActivityFormParticipantPayload } from '@components/activity/activity-form/activity-form-interfaces'
import NewParticipantModal from '@components/activity/activity-form/participant-manager/new-participant-modal'
import ParticipantList from '@components/activity/activity-form/participant-manager/participant-list'
import { ParticipantManagerProps } from '@components/activity/activity-form/participant-manager/participant-manager.interfaces'
import ProfileSearch from '@components/activity/activity-form/participant-manager/profile-search'
import Notifications from '@services/notifications'
import { useMemo, useState } from 'react'

const PaticipantManager = ({ field: { name }, form: { setFieldValue, values } }: ParticipantManagerProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const memoedParticipants = useMemo(() => values[name] as ActivityFormParticipantPayload[], [values[name]])

  const handleParticipantAdd = (newProfile: ActivityFormParticipantPayload) => {
    const previousValue = values[name] as ActivityFormParticipantPayload[]
    const isAlreadyAdded = previousValue.some(profile => profile.userId === newProfile.userId)

    if (isAlreadyAdded) {
      Notifications.onError(new Error('L\'utilisateur est déjà ajouté à la liste des participants'))
      return
    }

    if (values.participants_limit && previousValue.length === values.participants_limit) {
      Notifications.onError(new Error('Limite de participants atteinte'))
      return
    }

    if (!isAlreadyAdded) {
      setFieldValue(name, [...previousValue, newProfile])
    }
  }

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <div className='grid grid-cols-2 gap-x-4 gap-y-4'>
      <div>
        <ProfileSearch onAddParticipant={handleParticipantAdd} />

        <button className='mt-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' onClick={handleOpenModal} type='button'>
          Créer un nouveau participant
        </button>
      </div>

      <ParticipantList participants={memoedParticipants} />

      <NewParticipantModal isOpen={modalOpen} onClose={handleCloseModal} />
    </div>
  )
}

export default PaticipantManager
