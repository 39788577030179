import { UserInfo } from '@interfaces/api/user-info'
import create from 'zustand'

interface UserInfoStore {
  userInfo: UserInfo | null
  setUserInfo: (userInfo: UserInfo) => void
}

export const useUserInfoStore = create<UserInfoStore>((set) => ({
  userInfo: null,
  setUserInfo: (userInfo) => set({ userInfo })
}))
