import ZoneSelect from '@components/activity/activity-form/zone-select/zone-select'
import FieldRow from '@components/form/field-row/field-row'
import { Participations } from '@interfaces/api/participation'
import { Zone } from '@interfaces/api/zone'
import { Filters } from '@interfaces/components/filters'
import ParticipantTable from '@screens/participants/participant-table'
import ParticipantTableFilter from '@screens/participants/paticipant-table-filter'
import { useApiParticipations } from '@services/api/participations/participations'
import Notifications from '@services/notifications'
import { Form, Formik } from 'formik'
import { useCallback, useEffect, useState } from 'react'

interface FormValues {
  zone: Zone | null
}

const ITEM_BY_PAGE = 10
const AVAILABLE_FILTERS = [Filters.All, Filters.Coming, Filters.Finished, Filters.InProgress]

const OtherParticipantSection = () => {
  const [page, setPage] = useState(1)
  const [sortColumn, setSortColumn] = useState('id')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')
  const [selectedZoneId, setSelectedZoneId] = useState<number | null>(null)
  const [filter, setFilter] = useState<Filters>(Filters.All)

  const initialValues: FormValues = {
    zone: null
  }

  const handleSubmit = useCallback(async (values: FormValues) => {
    setSelectedZoneId(values.zone?.id ?? null)
  }, [])

  const handleFilterClick = useCallback((filter: Filters) => {
    setPage(1)
    setFilter(filter)
  }, [])

  const onLabelClick = useCallback((name: string) => {
    if (name !== sortColumn) {
      setSortColumn(name)
      return
    }

    setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC')
  }, [sortColumn, sortDirection])

  const { data, error } = useApiParticipations({
    _start: (page - 1) * ITEM_BY_PAGE,
    _limit: ITEM_BY_PAGE,
    _sort: `${sortColumn}:${sortDirection}`,
    activityStatusFilter: filter,
    activityZoneId: selectedZoneId
  }, !selectedZoneId)

  useEffect(() => {
    if (error) {
      Notifications.onError(error)
    }
  }, [error])

  const [participations, setParticipations] = useState<Participations | null>(null)

  useEffect(() => {
    if (data) {
      setParticipations(data)
    }
  }, [data])

  return <>
    <h2 className='text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate mb-3'>
      Autres participants
    </h2>

    <div className='mb-5'>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <div className='block max-w-xl'>
            <FieldRow
              component={ZoneSelect}
              label='Sélectionnez une zone '
              name='zone'
            />

            <button
              className='mt-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              type='submit'
            >
              Rechercher dans cette zone
            </button>
          </div>
        </Form>
      </Formik>
    </div>

    {participations
      ? (
        <>
          <ParticipantTableFilter
            filters={AVAILABLE_FILTERS}
            onClick={handleFilterClick}
            selectedFilter={filter}
          />
          <ParticipantTable
            itemPerPage={ITEM_BY_PAGE}
            onChangePage={setPage}
            onLabelClick={onLabelClick}
            page={page}
            participations={participations}
          />
        </>
      )
      : <p>Veuillez sélectionner une zone afin de voir les participants de cette zone.</p>}
  </>
}

export default OtherParticipantSection
