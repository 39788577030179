import moment from 'moment'

export const DATE_FORMAT = 'D MMMM YYYY'
export const TIME_FORMAT = 'HH:mm'

export const formatDate = (dateIso8601: string, format: string) => {
  const date = moment(dateIso8601, moment.ISO_8601)

  if (date.isValid()) {
    return date.format(format)
  }

  return dateIso8601
}

export const renderDate = (dateIso8601: string) => formatDate(dateIso8601, DATE_FORMAT)

export const renderDateTime = (dateIso8601: string) =>
  formatDate(dateIso8601, `${DATE_FORMAT} ${TIME_FORMAT}`)

export const renderTime = (dateIso8601: string) => formatDate(dateIso8601, TIME_FORMAT)

export const renderTimeTable = (dateIso8601: string, duration: number) => {
  const startAt = moment(dateIso8601, moment.ISO_8601)

  if (startAt.isValid()) {
    const endAt = startAt.clone().add(duration, 'hours')

    return `${startAt.format(TIME_FORMAT)} - ${endAt.format(TIME_FORMAT)}`
  }

  return ''
}

export const convertIso8601ToDate = (dateIso8601: string): Date =>
  moment(dateIso8601, moment.ISO_8601).toDate()
