import { PageTitleProps } from '@components/layout/page-title/page-title.interfaces'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

const PageTitle = ({ backRoute, title, buttons }: PageTitleProps) => {
  return (
    <div>
      {backRoute && (
        <div>
          <nav aria-label='Back'>
            <Link className='flex items-center text-sm font-medium text-gray-500 hover:text-gray-700' to={backRoute}>
              <ChevronLeftIcon aria-hidden='true' className='flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400' />
              Retour
            </Link>
          </nav>
        </div>
      )}

      <div className='mt-2 flex items-center justify-between'>
        <div className='flex-1 min-w-0'>
          <h2 className='text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate'>
            {title}
          </h2>
        </div>

        {buttons && (
          <div className='flex-shrink-0 flex mt-0 ml-4 gap-x-2'>
            {buttons}
          </div>
        )}
      </div>
    </div>
  )
}

export default PageTitle
