import create from 'zustand'
import { persist } from 'zustand/middleware'

interface ParticipantsCommentsStore {
  comments: string[]
  setComments: (comments: string[]) => void
}

export const useParticipantsCommentsStore = create<ParticipantsCommentsStore>(
  persist(
    (set) => {
      const config: ParticipantsCommentsStore = {
        comments: [],
        setComments: (comments) => set({ comments })
      }
      return config
    },
    {
      name: 'participants-comment-store', // unique name
      getStorage: () => localStorage
    }
  )
)
