import TableFooter from '@components/table/table-footer'
import { Activities, Activity } from '@interfaces/api/activity'
import ActivityDeleteModal from '@screens/activities/activity-delete-modal'
import ActivityTableHead from '@screens/activities/activity-table-head'
import ActivityTableLoading from '@screens/activities/activity-table-loading'
import ActivityTableRow from '@screens/activities/activity-table-row'
import { useApiActivities } from '@services/api/activities'
import Notifications from '@services/notifications'
import { useUserInfoStore } from '@services/stores/user-info'
import { useCallback, useEffect, useState } from 'react'


const ITEM_BY_PAGE = 10

const ActivityTable = () => {
  const { userInfo } = useUserInfoStore()

  // Activities loading parameters
  const [page, setPage] = useState(1)
  const [sortColumn, setSortColumn] = useState('id')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')

  const onLabelClick = useCallback((name: string) => {
    if (name !== sortColumn) {
      setSortColumn(name)
      return
    }

    setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC')
  }, [sortColumn, sortDirection])

  // Loading activities
  const { data, error, mutate } = useApiActivities({
    _start: (page - 1) * ITEM_BY_PAGE,
    _limit: ITEM_BY_PAGE,
    _sort: `${sortColumn}:${sortDirection}`
  })

  useEffect(() => {
    if (error) {
      Notifications.onError(error)
    }
  }, [error])

  // Store activities, so we can display something during page or sort change
  const [activities, setActivities] = useState<Activities | null>(null)

  useEffect(() => {
    if (data) {
      setActivities(data)
    }
  }, [data])


  // State & methods for delete modal

  const [activityToDelete, setActivityToDelete] = useState<Activity | null>(null)

  const onRequestDelete = useCallback((activity: Activity) => {
    setActivityToDelete(activity)
  }, [])

  const onDeleteModalRequestClose = useCallback(() => {
    setActivityToDelete(null)
  }, [])

  const onDeleteModalDeleteDone = useCallback(() => {
    mutate().finally(() => {
      setActivityToDelete(null)
    })
  }, [mutate])


  // Render

  const showHelper = !userInfo?.isAuthor

  return (
    <>
      <div className='overflow-x-auto'>
        <div className='inline-block min-w-full py-2'>
          <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg'>
            <table className='min-w-full divide-y divide-gray-300'>
              <ActivityTableHead onLabelClick={onLabelClick} showHelper={showHelper} />

              <tbody className='bg-white divide-y divide-gray-200'>
                {
                  !data && activities && (
                    <ActivityTableLoading showHelper={showHelper} smaller />
                  )
                }

                {
                  activities
                    ? activities.results.map((activity) => (
                      <ActivityTableRow
                        activity={activity}
                        key={activity.id}
                        onRequestDelete={onRequestDelete}
                        showHelper={showHelper}
                      />
                    ))
                    : <ActivityTableLoading showHelper={showHelper} />
                }
              </tbody>
            </table>

            <TableFooter
              gotoPage={setPage}
              itemPerPage={ITEM_BY_PAGE}
              meta={activities?.meta ?? null}
              page={page}
            />
          </div>
        </div>
      </div>

      <ActivityDeleteModal
        activity={activityToDelete}
        onDeleteDone={onDeleteModalDeleteDone}
        onRequestClose={onDeleteModalRequestClose}
      />
    </>
  )
}

export default ActivityTable
