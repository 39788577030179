import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import { ParticipantTableRowProps } from '@screens/participants/participant-table-row.interfaces'
import { renderDate } from '@services/utils/date-utils'
import classNames from 'classnames'
import { memo, useCallback, useMemo } from 'react'

const requiredProfileData = ['first_name', 'last_name', 'picture', 'zone', 'languages']

const ParticipantTableRow = ({ participation, onSeeProfileClick }: ParticipantTableRowProps) => {
  const fullName = useMemo(() => {
    if (participation.profile?.first_name && participation?.profile?.last_name) {
      return `${participation.profile.first_name} ${participation.profile.last_name.toUpperCase()}`
    }
    return 'N/A'
  }, [participation?.profile?.first_name, participation?.profile?.last_name])
  const isCvAvailable = useMemo(() => {
    return requiredProfileData.every((p) => participation?.profile?.[p] !== null)
  }, [participation?.profile])

  const handleClick = useCallback(() => {
    if (isCvAvailable) {
      onSeeProfileClick(participation.id)
    }
  }, [isCvAvailable, participation.id, onSeeProfileClick])

  return (
    <tr key={participation.id}>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {participation.id}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {fullName}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {participation.user.email ?? 'N/A'}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {participation.created_at ? renderDate(participation.created_at) : 'N/A'}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {participation.activity}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          <button
            className={classNames(
              'flex items-center py-0.5',
              isCvAvailable ? 'text-indigo-600 hover:text-indigo-900' : 'text-gray-600'
            )}
            disabled={!isCvAvailable}
            onClick={handleClick}
            title={isCvAvailable ? 'Voir le CV' : 'CV indisponible car le profil de l\'utilisateur n\'est pas complet'}
          >
            {isCvAvailable ? <EyeIcon className='h-5 mr-2 w-auto' /> : <EyeOffIcon className='h-5 mr-2 w-auto' />}

            <span>{isCvAvailable ? 'Voir le CV' : 'CV indisponible'}</span>
          </button>

        </div>
      </td>
    </tr>
  )
}

export default memo(ParticipantTableRow)
