import PrimaryButton from '@components/button/primary-button'
import ErrorPage from '@components/error-page'
import PageTitle from '@components/layout/page-title/page-title'
import FullPageLoading from '@components/loading/full-page-loading'
import UserForm from '@components/user/user-form/user-form'
import { UserFormValues } from '@components/user/user-form/user-form.interfaces'
import { SaveIcon } from '@heroicons/react/solid'
import { AdminUser } from '@interfaces/api/user'
import { RoutesName } from '@navigation/routes-name'
import { apiEditAdminUser } from '@services/api/user'
import { useApiAdminUser } from '@services/api/user/admin'
import Notifications from '@services/notifications'
import { stringifyError } from '@services/utils/error-utils'
import { FormikProps } from 'formik/dist/types'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const UserEdit = () => {
  const { id: idParam } = useParams()
  const id = Number.parseInt(idParam ?? '', 10)
  const navigate = useNavigate()

  // load user from api
  const { data, error, isValidating } = useApiAdminUser(id)

  // Use user state so we can save edited activity
  const [user, setUser] = useState<AdminUser | null>(null)

  useEffect(() => {
    if (data && !isValidating) {
      setUser(data)
    }
  }, [data, isValidating])

  // Bound formik props from children
  let formikProps: FormikProps<UserFormValues> | null = null
  const bindFormikProps = (props: FormikProps<UserFormValues>) => {
    formikProps = props
  }

  // On Save button, we submit form
  const onSaveButtonClick = async () => {
    if (formikProps) {
      const errors = await formikProps.validateForm()

      if (Object.keys(errors).length > 0) {
        Notifications.addWarningMessage('Le formulaire est invalide')
      }

      await formikProps.submitForm()
    }
  }

  // On form submit, save the user
  const [isSubmitting, setIsSubmitting] = useState(false)
  const onFormSubmit = useCallback(async (values: UserFormValues): Promise<AdminUser> => {
    if (!user) {
      throw new Error('Pas d\'utilisateur')
    }

    setIsSubmitting(true)
    return await apiEditAdminUser(user.id, values)
      .then(u => {
        Notifications.addSuccessMessage('Utilisateur modifié')
        setTimeout(() => navigate(RoutesName.USERS), 50)
        return u
      })
      .catch(err => {
        Notifications.onError(err)
        throw err
      }).finally(() => {
        setIsSubmitting(false)
      })
  }, [user])

  if (error) {
    return (
      <ErrorPage
        message={stringifyError(error)}
        title={'Erreur lors du chargement de l\'utilisateur'}
      />
    )
  }

  if (!user) {
    return (
      <FullPageLoading text={'Chargement'} />
    )
  }

  const buttons = <>
    <PrimaryButton
      className='w-auto text-md'
      disabled={isSubmitting}
      isLoading={isSubmitting}
      onClick={onSaveButtonClick}
    >
      <SaveIcon aria-hidden='true' className='-ml-0.5 mr-2 h-4 w-4' />

      {'Enregistrer'}
    </PrimaryButton>
  </>

  return (
    <div className='p-4'>
      <PageTitle
        backRoute={RoutesName.USERS}
        buttons={buttons}
        title={'Éditer un utilisateur'}
      />

      <div className='p-4 pb-10'>
        <UserForm bindFormikProps={bindFormikProps} onFormSubmit={onFormSubmit} user={user} />
      </div>
    </div>
  )
}

export default UserEdit
