import PageTitle from '@components/layout/page-title/page-title'
import Menu from '@components/menu'
import BadgeTable from '@screens/badges/badge-table'

const Badges = () => {
  return (
    <div className='p-4'>
      <Menu />

      <PageTitle title={'Badges'} />

      <div className='pt-4'>
        <BadgeTable />
      </div>
    </div>
  )
}

export default Badges
