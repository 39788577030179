import ActivityForm from '@components/activity/activity-form'
import { ActivityFormValues } from '@components/activity/activity-form/activity-form-interfaces'
import { convertActivityFormValuesToActivitySavePayload } from '@components/activity/activity-form/activity-form-utils'
import Button from '@components/button/button'
import PrimaryButton from '@components/button/primary-button'
import SecondaryButton from '@components/button/secondary-button'
import SuccessButton from '@components/button/success-button'
import WarningButton from '@components/button/warning-button'
import ErrorPage from '@components/error-page'
import PageTitle from '@components/layout/page-title/page-title'
import FullPageLoading from '@components/loading/full-page-loading'
import { StarIcon as OutlineStarIcon } from '@heroicons/react/outline'
import { CheckCircleIcon, DuplicateIcon, SaveIcon, StarIcon, XCircleIcon } from '@heroicons/react/solid'
import { Activity } from '@interfaces/api/activity'
import { ActivityDetailed } from '@interfaces/api/activity-detailed'
import { RoutesName } from '@navigation/routes-name'
import { useExternalAppApiActivityDetailed } from '@services/api/activities'
import { apiPublishActivity } from '@services/api/activities/activity-publish'
import { apiEditActivity } from '@services/api/activities/activity-save'
import Notifications from '@services/notifications'
import { useUserInfoStore } from '@services/stores/user-info'
import { stringifyError } from '@services/utils/error-utils'
import { FormikProps } from 'formik/dist/types'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const ActivityEdit = () => {
  const { userInfo } = useUserInfoStore()
  const { id: idParam } = useParams()
  const id = Number.parseInt(idParam ?? '', 10)

  const navigate = useNavigate()

  // load activity from api
  const { data, error, isValidating } = useExternalAppApiActivityDetailed(id)

  // Use activity state so we can save edited activity
  const [activity, setActivity] = useState<ActivityDetailed | null>(null)

  useEffect(() => {
    if (data && !isValidating) {
      setActivity(data)
    }
  }, [data, isValidating])

  // Bound formik props from children
  let formikProps: FormikProps<ActivityFormValues> | null = null
  const bindFormikProps = (props: FormikProps<ActivityFormValues>) => {
    formikProps = props
  }

  // On Save button, we submit form
  const onSaveButtonClick = async () => {
    if (formikProps) {
      const errors = await formikProps.validateForm()

      if (Object.keys(errors).length > 0) {
        Notifications.addWarningMessage('Le formulaire est invalide')
      }

      await formikProps.submitForm()
    }
  }

  // Publish and un-publish activity
  const [isPublishing, setIsPublishing] = useState(false)
  const onPublishButtonClick = useCallback(async () => {
    if (!activity) {
      throw new Error('Pas d\'activité')
    }

    setIsPublishing(true)
    apiPublishActivity(activity.id, !activity.published_at)
      .then((entity) => {
        setActivity(entity)
        if (entity.published_at) {
          Notifications.addSuccessMessage('Votre activité est publiée en ligne')
        } else {
          Notifications.addInfoMessage('Votre activité vient d\'être dépubliée')
        }
      })
      .catch(e => {
        Notifications.onError(e)
      })
      .finally(() => {
        setIsPublishing(false)
      })
  }, [activity])

  const onDuplicateButtonClick = useCallback(async () => {
    if (activity) {
      navigate(RoutesName.ACTIVITY_CREATE, {
        replace: false,
        state: {
          duplicate: {
            ...activity,
            // clean participants
            participations: [],
            participantProfiles: []
          }
        }
      })
    }
  }, [activity])

  // On form submit, save the activity
  const [isSubmitting, setIsSubmitting] = useState(false)
  const onFormSubmit = useCallback(async (values: ActivityFormValues): Promise<Activity> => {
    if (!activity) {
      throw new Error('Pas d\'activité')
    }

    setIsSubmitting(true)

    return await apiEditActivity(activity.id, convertActivityFormValuesToActivitySavePayload(values))
      .then(activity => {
        Notifications.addSuccessMessage('Activité sauvegardé')
        return activity
      })
      .catch(err => {
        Notifications.onError(err)
        throw err
      }).finally(() => {
        setIsSubmitting(false)
      })
  }, [activity])

  const [duplicableClickLoading, setDuplicableClickLoading] = useState(false)
  const handleDuplicableClick = useCallback(async () => {
    if (!activity) {
      throw new Error('Pas d\'activité')
    }
    if (!userInfo?.isSuperAdmin) {
      throw new Error('Vous n\'êtes pas autorisé à réaliser cette action')
    }

    setDuplicableClickLoading(true)

    const duplicable = !activity.duplicable

    return await apiEditActivity(activity.id, { duplicable })
      .then(async (activity) => {
        Notifications.addSuccessMessage('Activité sauvegardé')
        setActivity((prev) => ({ ...prev as ActivityDetailed, duplicable }))
        return activity
      })
      .catch(err => {
        Notifications.onError(err)
        throw err
      }).finally(() => {
        setDuplicableClickLoading(false)
      })
  }, [activity])

  if (error) {
    return (
      <ErrorPage
        message={stringifyError(error)}
        title={'Erreur lors du chargement de l\'activité'}
      />
    )
  }

  if (!activity) {
    return (
      <FullPageLoading text={'Chargement'} />
    )
  }

  const buttons = <>
    {userInfo?.isSuperAdmin && (
      <Button
        className='w-auto text-md bg-yellow-400 border-yellow-500 text-white hover:bg-yellow-500'
        disabled={duplicableClickLoading}
        onClick={handleDuplicableClick}
      >
        {activity?.duplicable
          ? <StarIcon aria-hidden='true' className='-ml-0.5 mr-2 h-4 w-4' />
          : <OutlineStarIcon aria-hidden='true' className='-ml-0.5 mr-2 h-4 w-4' />}

        {activity?.duplicable ? 'Enlever des activités type' : 'Marquer comme activité type'}
      </Button>
    )}
    <SecondaryButton
      className='w-auto text-md'
      disabled={isSubmitting || isPublishing}
      onClick={onDuplicateButtonClick}
    >
      <DuplicateIcon aria-hidden='true' className='-ml-0.5 mr-2 h-4 w-4' />

      {'Dupliquer'}
    </SecondaryButton>
    {activity.published_at
      ? (
        <WarningButton
          className='w-auto text-md'
          disabled={isSubmitting || isPublishing}
          isLoading={isPublishing}
          onClick={onPublishButtonClick}
        >
          <XCircleIcon aria-hidden='true' className='-ml-0.5 mr-2 h-4 w-4' />

          {'Dépublier'}
        </WarningButton>
      )
      : (
        <SuccessButton
          className='w-auto text-md'
          disabled={isSubmitting || isPublishing}
          isLoading={isPublishing}
          onClick={onPublishButtonClick}
        >
          <CheckCircleIcon aria-hidden='true' className='-ml-0.5 mr-2 h-4 w-4' />

          {'Publier'}
        </SuccessButton>
      )}
    <PrimaryButton
      className='w-auto text-md'
      disabled={isSubmitting || isPublishing}
      isLoading={isSubmitting}
      onClick={onSaveButtonClick}
    >
      <SaveIcon aria-hidden='true' className='-ml-0.5 mr-2 h-4 w-4' />

      {'Enregistrer'}
    </PrimaryButton>
  </>

  return (
    <div className='p-4'>
      <PageTitle
        backRoute={RoutesName.ACTIVITIES}
        buttons={buttons}
        title={'Éditer une activité'}
      />

      <div className='p-4 pb-10'>
        <ActivityForm activity={activity} bindFormikProps={bindFormikProps} onFormSubmit={onFormSubmit} />
      </div>
    </div>
  )
}

export default ActivityEdit
