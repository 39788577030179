import DangerButton from '@components/button/danger-button'
import PrimaryButton from '@components/button/primary-button'
import SecondaryButton from '@components/button/secondary-button'
import ImageUploadCurrent from '@components/image/image-upload-modal/image-upload-current'
import ImageUploadDropzone from '@components/image/image-upload-modal/image-upload-dropzone'
import { ImageUploadModalProps } from '@components/image/image-upload-modal/image-upload-modal.interfaces'
import Modal from '@components/modal'
import { Dialog } from '@headlessui/react'
import { apiImageUpload } from '@services/api/images/image-upload'
import Notifications from '@services/notifications'
import { useCallback, useRef, useState } from 'react'

const ImageUploadModal = ({ show, onRequestClose, onSelect }: ImageUploadModalProps) => {
  const [droppedFile, setDroppedFile] = useState<File | null>()
  const [isUploading, setIsUploading] = useState(false)

  const closeButtonRef = useRef(null)

  const onUploadClick = useCallback(() => {
    if (!droppedFile) {
      return
    }

    setIsUploading(true)

    const data = new FormData()
    data.append('files', droppedFile)
    apiImageUpload(data)
      .then((images) => {
        onSelect(images[0])
      }).catch((e) => {
        Notifications.onError(e)
      }).finally(() => {
        setIsUploading(false)
      })
  }, [droppedFile])

  const onCancelClick = useCallback(() => {
    setDroppedFile(null)
  }, [])

  return (
    <Modal initialFocus={closeButtonRef} onRequestClose={onRequestClose} show={show}>
      <div className='inline-flex flex-col w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md'>

        <div className='text-center p-3 bg-indigo-600'>
          <Dialog.Title
            as='h3'
            className='text-lg leading-6 font-medium text-white'
          >
            {'Envoyer une image'}
          </Dialog.Title>
        </div>

        <div className='grow p-2'>
          {
            droppedFile
              ? <ImageUploadCurrent file={droppedFile} />
              : <ImageUploadDropzone onDropFile={setDroppedFile} />
          }
        </div>

        <div className='bg-gray-200 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-between'>
          <div>
            <DangerButton
              className='ml-3 w-auto text-sm'
              disabled={isUploading || !droppedFile}
              onClick={onCancelClick}
              type='button'
            >
              {'Annuler'}
            </DangerButton>

            <PrimaryButton
              className='ml-3 w-auto text-sm'
              disabled={isUploading || !droppedFile}
              isLoading={isUploading}
              onClick={onUploadClick}
              type='button'
            >
              {'Envoyer'}
            </PrimaryButton>
          </div>

          <SecondaryButton
            className='w-auto text-sm'
            disabled={isUploading}
            onClick={onRequestClose}
            ref={closeButtonRef}
            type='button'
          >
            {'Fermer'}
          </SecondaryButton>
        </div>
      </div>
    </Modal>
  )
}

export default ImageUploadModal
