import ActivityCard from '@components/card/activity-card'
import LoadingBox from '@components/loading/loading-box'
import { Activities, Activity } from '@interfaces/api/activity'
import { RoutesName } from '@navigation/routes-name'
import { useApiActivitiesType } from '@services/api/activities'
import Notifications from '@services/notifications'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ActivityGrid = () => {
  const navigate = useNavigate()
  const [activities, setActivities] = useState<Omit<Activities, 'meta'> | null>(null)

  const { data, error } = useApiActivitiesType()

  const handleActivityClick = useCallback((activity: Activity) => {
    if (activity) {
      navigate(RoutesName.ACTIVITY_CREATE, {
        replace: false,
        state: {
          duplicate: {
            ...activity,
            helper: null,
            duplicable: false,
            participations: [],
            participantProfiles: []
          },
          previousRoute: RoutesName.ACTIVITIES_TYPE
        }
      })
    }
  }, [])

  useEffect(() => {
    if (error) {
      Notifications.onError(error)
    }
  }, [error])

  useEffect(() => {
    if (data) {
      setActivities(data)
    }
  }, [data])

  return <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
    {!data && activities && <LoadingBox />}

    {activities && activities?.results?.length > 0
      ? activities?.results?.map((ac) => {
        return <ActivityCard
          activity={ac}
          key={`activity-card-${ac.id}`}
          onClick={() => handleActivityClick(ac)}
        />
      })
      : <p className='col-span-4'>Aucune activité type pour le moment.</p>}
  </div>
}


export default ActivityGrid
