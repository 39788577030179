import ZoneSelectItems from '@components/activity/activity-form/zone-select/zone-select-items'
import { ZoneSelectProps } from '@components/activity/activity-form/zone-select/zone-select.interfaces'
import { Combobox } from '@headlessui/react'
import { SelectorIcon } from '@heroicons/react/solid'
import { Zone } from '@interfaces/api/zone'
import { getZoneTypeLabel } from '@services/utils/zone-utils'
import { useCallback, useState } from 'react'

const ZoneSelect = ({
  field,
  form: { setFieldValue },
  label
}: ZoneSelectProps) => {
  const [query, setQuery] = useState('')

  const onSelect = useCallback((zone: Zone) => {
    setFieldValue(field.name, zone)
    setQuery('')
  }, [setFieldValue, field.name])

  const displayValue = useCallback((zone: Zone) => {
    return `${zone?.name ?? ''}`
  }, [])

  const onBlur = useCallback(() => {
    // Add setTimeout because it may be called before onSelect, and sometime, cancel it
    setTimeout(() => {
      setQuery('')
    }, 100)
  }, [])

  const onChange = useCallback((event) => {
    setQuery(event.target.value)
  }, [])

  return (
    <Combobox onChange={onSelect} value={field.value}>
      <Combobox.Label className='block text-sm font-medium text-gray-700'>
        {`${label ?? 'Zone'}:`}
      </Combobox.Label>

      <div className='relative mt-1'>
        <div className='relative'>
          <Combobox.Input<'input', Zone>
            autoComplete='off'
            className='w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm h-12'
            displayValue={displayValue}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={'Entrez quelques caractères, ou cliquez sur l\'icône à droite'}
          />

          <div className='absolute inset-y-0 right-0 flex items-center'>
            <span className='text-sm text-gray-500'>
              {field.value && getZoneTypeLabel(field.value.type)}
            </span>

            <Combobox.Button className='px-2 focus:outline-none'>
              <SelectorIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />
            </Combobox.Button>
          </div>
        </div>

        <Combobox.Options className='absolute z-10 mt-1 max-h-80 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
          <ZoneSelectItems query={query} value={field.value} />
        </Combobox.Options>
      </div>
    </Combobox>
  )
}

export default ZoneSelect
