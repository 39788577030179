import { CoverManagerProps } from '@components/activity/activity-form/cover-manager/cover-manager.interfaces'
import ImageElement from '@components/image/image-element'
import ImageLibraryModal from '@components/image/image-library-modal'
import ImagePlaceholder from '@components/image/image-placeholder'
import ImageUploadModal from '@components/image/image-upload-modal'
import { Image } from '@interfaces/api/image'
import { useCallback, useState } from 'react'

const CoverManager = ({
  field,
  form: { setFieldValue }
}: CoverManagerProps) => {
  const [showLibraryModal, setShowLibraryModal] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)

  const closeLibraryModal = useCallback(() => {
    setShowLibraryModal(false)
  }, [])

  const openLibraryModal = useCallback(() => {
    setShowLibraryModal(true)
  }, [])

  const closeUploadModal = useCallback(() => {
    setShowUploadModal(false)
  }, [])

  const openUploadModal = useCallback(() => {
    setShowUploadModal(true)
  }, [])

  const onSelect = useCallback((image: Image) => {
    setFieldValue(field.name, image)
    closeLibraryModal()
    closeUploadModal()
  }, [setFieldValue, field.name])

  return (
    <>
      <div className='mt-1 flex max-w-full'>
        <div className='flex items-center justify-center w-80 max-w-full aspect-video rounded-lg bg-gray-100 overflow-hidden'>
          <ImageElement
            className='max-h-full w-auto'
            image={field.value}
            placeholder={<ImagePlaceholder className='h-full w-80 max-w-full  ' text={'Pas d\'image'} />}
            preferredSize={'medium'}
          />
        </div>

        <div className='flex flex-col justify-around'>
          <button
            className='ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={openLibraryModal}
            type='button'
          >
            Bibliothèque
          </button>

          <button
            className='ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={openUploadModal}
            type='button'
          >
            Ajouter une image
          </button>
        </div>
      </div>

      <ImageLibraryModal onRequestClose={closeLibraryModal} onSelect={onSelect} show={showLibraryModal} />

      <ImageUploadModal onRequestClose={closeUploadModal} onSelect={onSelect} show={showUploadModal} />
    </>
  )
}

export default CoverManager
