import { ButtonProps } from '@components/button/button.interfaces'
import Spinner from '@components/loading/spinner'
import classNames from 'classnames'
import { forwardRef } from 'react'

const Button = ({ className, children, isLoading = false, spinnerColor, ...props }: ButtonProps, ref) => {
  return (
    <button
      {...props}
      className={classNames(
        'relative inline-flex justify-center items-center rounded-md border shadow-sm px-4 py-2 bg-white font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        className
      )}
      ref={ref}
    >
      {children}

      {props.disabled && (
        <>
          <div className='absolute inset-0 bg-white opacity-50'>
            {' '}
          </div>
          {isLoading && (
            <div className='absolute inset-0 p-2 flex items-center justify-center'>
              <Spinner className='h-full w-auto' color={spinnerColor} />
            </div>
          )}
        </>
      )}
    </button>
  )
}

export default forwardRef<HTMLButtonElement, ButtonProps>(Button)
