import PrimaryButton from '@components/button/primary-button'
import SecondaryButton from '@components/button/secondary-button'
import Modal from '@components/modal'
import { Dialog } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/solid'
import { ActivityDeleteModalProps } from '@screens/activities/activity-delete-modal.interfaces'
import { apiDeleteActivity } from '@services/api/activities/activity-delete'
import Notifications from '@services/notifications'
import { memo, useCallback, useRef, useState } from 'react'

const ActivityDeleteModal = ({ activity, onRequestClose, onDeleteDone }: ActivityDeleteModalProps) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const closeButtonRef = useRef(null)

  const onConfirm = useCallback(() => {
    if (isDeleting || !activity) {
      return
    }

    setIsDeleting(true)
    apiDeleteActivity(activity.id)
      .then(() => {
        onDeleteDone()
      }).catch((e) => {
        Notifications.onError(e)
      }).finally(() => {
        setIsDeleting(false)
      })
  }, [activity, isDeleting, onDeleteDone])

  return (
    <Modal initialFocus={closeButtonRef} onRequestClose={onRequestClose} show={!!activity}>
      <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
        <div className='sm:flex sm:items-start'>
          <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
            <ExclamationIcon aria-hidden='true' className='h-6 w-6 text-red-600' />
          </div>

          <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              {'Confirmer la suppression'}
            </Dialog.Title>

            <div className='mt-2'>
              <p className='text-sm text-gray-500'>
                {'Êtes-vous sure de vouloir supprimer cette activité définitivement.'}
              </p>
            </div>
          </div>
        </div>

        <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
          <PrimaryButton
            className='mt-3 ml-3 w-auto text-sm'
            disabled={isDeleting}
            isLoading={isDeleting}
            onClick={onConfirm}
            type='button'
          >
            {'Je confirme'}
          </PrimaryButton>

          <SecondaryButton
            className='mt-3 ml-3 w-auto text-sm'
            disabled={isDeleting}
            onClick={onRequestClose}
            ref={closeButtonRef}
            type='button'
          >
            {'Annuler'}
          </SecondaryButton>
        </div>
      </div>
    </Modal>
  )
}

export default memo(ActivityDeleteModal)
