import LoadingBox from '@components/loading/loading-box'
import { ActivityTableLoadingProps } from '@screens/activities/activity-table-loading.interfaces'

const ActivityTableLoading = ({ showHelper, smaller = false }: ActivityTableLoadingProps) => (
  <tr className='bg-gray-50'>
    <td colSpan={showHelper ? 9 : 8}>
      <div className={smaller ? 'py-4 h-16' : 'py-16 h-56'}>
        <LoadingBox />
      </div>
    </td>
  </tr>
)

export default ActivityTableLoading
