import { Badge, CategoryGroupedBadges } from '@interfaces/api/badge'

function generateRandomNumberNotInArray (array: number[]) {
  let output: number

  do {
    output = Math.floor(Math.random() * 31) // n + 1
  } while (array.includes(output))

  return output
}

export const groupBadgesByCategory = (badges: Badge[]) => {
  if (badges?.length < 1) {
    return []
  }

  const groupedBadges: CategoryGroupedBadges[] = []
  const badgesWithTheme = badges.filter(badge => badge?.theme)

  badgesWithTheme?.forEach(badge => {
    const categoryId = groupedBadges.findIndex((category, index) => badge?.theme?.id === index)
    if (categoryId === -1 && badge?.theme?.id) {
      groupedBadges[badge.theme.id] = {
        name: badge.theme.name,
        id: `category${badge.theme.id}`,
        badges: [badge]
      }
    } else {
      groupedBadges?.[categoryId]?.badges?.push(badge)
    }
  })

  const categoriesIndex = groupedBadges.reduce<number[]>((acc, val, index) => {
    acc.push(index)
    return acc
  }, [])
  const noCategoryIndex = generateRandomNumberNotInArray(categoriesIndex)

  groupedBadges[noCategoryIndex] = {
    name: 'Sans catégorie',
    id: 'category_no_category',
    badges: badges.filter(badge => !badge?.theme)
  }

  return groupedBadges
}
