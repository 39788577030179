import ErrorPage from '@components/error-page'
import Initial from '@screens/initial'
import { useStartup } from '@services/helpers/startup'

const App = () => {
  const { isReady, error } = useStartup()

  if (!isReady) {
    return null
  }

  if (error) {
    return (
      <ErrorPage
        message={error}
        title={'Erreur lors de l\'initialisation de l\'application'}
      />
    )
  }

  return (
    <Initial />
  )
}

export default App
