import { Zones } from '@interfaces/api/zone'
import { axiosGet } from '@services/api/axios'
import { loadOnceSwrConfig } from '@services/api/swr-config'
import useSwr, { Fetcher } from 'swr'

export const useApiZones = () => {
  const { data, error } = useSwr<Zones>(
    '/external-app-plugin/zones?_sort=dept_number:asc,name:asc&_limit=-1',
    axiosGet as Fetcher<Zones>,
    loadOnceSwrConfig
  )

  return {
    data,
    error
  }
}
