import { ParticipantListProps } from '@components/activity/activity-form/participant-manager/participant-list.interfaces'
import ImageElement from '@components/image/image-element'
import ImagePlaceholder from '@components/image/image-placeholder'

const ParticipantList = ({ participants }: ParticipantListProps) => {
  return (
    <div>
      <p>Liste des participants</p>

      {participants?.length === 0
        ? <p>Aucun participant</p>
        : <div className='flex flex-wrap mt-2'>
          {participants.map((participant) => (
            <div className='flex items-center mb-2 mr-2' key={`participant-list-${participant.userId}`}>
              <ImageElement
                className='h-8 w-8 object-cover mr-2 rounded-full'
                image={participant?.picture ?? null}
                placeholder={<ImagePlaceholder className='h-8 w-8 mr-2 rounded-full' />}
                preferredSize={'thumbnail'}
              />

              <div className='flex flex-col justify-center'>
                <span className='font-bold'>
                  {participant.firstName && participant.lastName ? participant.firstName + ' ' + participant.lastName : 'Utilisateur inconnu'}
                </span>

                {participant.email && <span className='text-xs italic'>
                  (
                  {participant.email}
                  )
                </span>}
              </div>
            </div>
          ))}
        </div>}

    </div>
  )
}

export default ParticipantList
