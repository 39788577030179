import FieldRow from '@components/form/field-row'
import { ParticipationHeadProps } from '@components/participation-head'
import { ParticipationFormProps } from '@screens/activity-completion/badges.interfaces'
import { useParticipantsCommentsStore } from '@services/stores/activity-participants-comments'
import { FormikContextType, useFormikContext } from 'formik'
import { useEffect } from 'react'

const ParticipationHead = ({ participation, index, total, defaultComment }: ParticipationHeadProps) => {
  const { comments, setComments } = useParticipantsCommentsStore()

  // Si on entre du texte dans ce champ
  // Update tous les commentaires
  const { values, setValues, touched }: FormikContextType<ParticipationFormProps> = useFormikContext()
  useEffect(() => {
    if ((values.comment !== defaultComment && values.comment !== '') || touched.comment) {
      setValues({
        ...values,
        badges: values.badges.map(badge => ({
          ...badge,
          comment: values.comment
        }))
      })
      // Stocke le commentaire dans une collection
      // Placée dans un store
      comments[participation.id] = values.comment
      // console.log('updating to ', values.comment)
      setComments(comments)
    }
  }, [values.comment])

  return (
    <div className='flex flex-col bg-white rounded-3xl'>
      {/* Profile */}

      <div className='flex items-center justify-between gap-8 p-6 relative'>
        <div className='flex flex-col items-start sm:flex-row sm:items-center gap-8'>
          <img alt='' className='shrink-0 w-20 h-20 rounded-full' src={participation.profile.picture?.url ?? '/assets/avatar-placeholder.png'} />

          <div className='flex-1'>
            <div className='text-h3 font-bold'>
              {`${participation.profile.first_name} `}

              {participation.profile.last_name}
            </div>

            <div className='text-mono-gray'>
              {participation.user.email}
            </div>
          </div>
        </div>

        <div className='bg-yellow-500 font-bold px-5 py-2 rounded-full mb-auto absolute top-6 right-6'>
          {`${index}/${total}`}
        </div>
      </div>

      {/* Comment */}

      <div className={'flex flex-col gap-4 p-6 border-t border-mono-separator'}>
        <FieldRow
          as='textarea'
          fieldStyles='mt-2 p-0 border-none placeholder:text-mono-lightgray'
          id='badge_comment'
          label='Ajouter une recommandation personnalisée'
          labelStyles='font-bold text-mono-gray'
          name='comment'
          placeholder='Votre recommandation...'
        />
      </div>
    </div>
  )
}

export default ParticipationHead
