import Spinner from '@components/loading/spinner'

const SelectLoading = () => {
  return (
    <div className='flex items-center space-x-4 p-3'>
      <Spinner className='h-8 w-auto' />

      <span className='text-sm font-medium text-gray-500'>Chargement</span>
    </div>
  )
}

export default SelectLoading
