import { ErrorPageProps } from '@components/error-page/error-page.interfaces'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { RoutesName } from '@navigation/routes-name'
import { Link } from 'react-router-dom'

const ErrorPage = ({ title, message }: ErrorPageProps) => {
  return (
    <div className='min-h-screen pt-16 pb-12 flex flex-col'>
      <main className='flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='flex-shrink-0 flex justify-center'>
          <ExclamationCircleIcon className='h-24 w-auto text-red-600' />
        </div>

        <div className='py-8'>
          <div className='text-center'>
            <h1 className='mt-2 text-2xl font-extrabold text-gray-900 tracking-tight'>
              {title}
            </h1>

            <p className='mt-2 text-base text-gray-500'>{message}</p>

            <p className='mt-3'>
              <Link className='text-indigo-600 hover:text-indigo-900' to={RoutesName.HOME}>
                {'Retour à la page d\'accueil'}
              </Link>
            </p>
          </div>
        </div>
      </main>
    </div>
  )
}

export default ErrorPage
