import { BadgeProps } from '@components/badge-finder/badge'
import { CheckIcon } from '@heroicons/react/outline'
import { useField } from 'formik'

const Badge = ({ icon, name, label, id }: BadgeProps) => {
  const [field] = useField({
    name: name,
    type: 'checkbox',
    value: id.toString()
  })
  const { checked } = field
  const toggleStyles = checked ? 'bg-blue-400 border-blue' : 'bg-white border-mono-separator hover:border-mono-separator-hover text-mono-black'
  return (
    <label className={`relative flex items-center p-4 border rounded-3xl text-left cursor-pointer ${toggleStyles}`}>
      <input className='hidden' type='checkbox' {...field} />

      <div className={`absolute top-3 right-3 w-6 h-6 p-1 bg-blue rounded-full transition ${checked ? 'scale-100 opacity-1' : 'scale-50 opacity-0'}`}>
        <CheckIcon className='w-4 h-4 text-white' />
      </div>

      <img alt='' className='w-20 h-20 mr-4' src={icon} />

      {label}
    </label>
  )
}

export default Badge
