import { InformationCircleIcon } from '@heroicons/react/outline'
import { ChildrenProps } from '@interfaces/components/children'

const Infoblock = ({ children }: ChildrenProps) => {
  return (
    <div className='flex flex-col sm:flex-row items-center gap-4 bg-blue-400 mt-6 p-6 rounded-3xl text-blue'>
      <InformationCircleIcon className='w-6 h-6 shrink-0' />

      <div>
        {children}
      </div>
    </div>
  )
}

export default Infoblock
