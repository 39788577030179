import LoadingBox from '@components/loading/loading-box'
import { ParticipantTableLoadingProps } from '@screens/participants/participant-table-loading.interfaces'

const ParticipantTableLoading = ({ smaller = false }: ParticipantTableLoadingProps) => (
  <tr className='bg-gray-50'>
    <td colSpan={8}>
      <div className={smaller ? 'py-4 h-16' : 'py-16 h-56'}>
        <LoadingBox />
      </div>
    </td>
  </tr>
)

export default ParticipantTableLoading
