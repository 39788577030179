import { BadgeFinderProps } from '@components/badge-finder'
import Badge from '@components/badge-finder/badge'
import CategoryFilter from '@components/badge-finder/category-filter'
import Button from '@components/button/fancy-button'
import FieldRow from '@components/form/field-row'
import Modal from '@components/modal'
import { XIcon } from '@heroicons/react/outline'
import { CategoryGroupedBadges, Badge as IBadge } from '@interfaces/api/badge'
import { useApiThemes } from '@services/api/themes'
import { groupBadgesByCategory } from '@services/utils/badges-utils'
import { fuzzyMatch } from '@services/utils/string-utils'
import { Form, Formik } from 'formik'

const BadgeFinder = ({ currentBadges, show, onRequestClose, badges }: BadgeFinderProps) => {
  const { data: themes } = useApiThemes()

  const groupedBadges = badges ? groupBadgesByCategory(badges) : []


  const filterByCategories = (groupedBadges: CategoryGroupedBadges[], filteredCategories: string[]) => {
    let filteredContent = groupedBadges
    if (filteredCategories.length > 0) {
      filteredContent = groupedBadges.reduce((filteredResult: CategoryGroupedBadges[], category) => {
        const categoryShoudBeDisplayed = filteredCategories.includes(category.id)
        if (categoryShoudBeDisplayed) {
          filteredResult.push(category)
        }
        return filteredResult
      }, [])
    }
    return filteredContent
  }

  const filterBySearchTerm = (groupedBadges: CategoryGroupedBadges[], searchTerm: string) => {
    let filteredContent = groupedBadges
    if (searchTerm !== '') {
      filteredContent = groupedBadges.reduce((result: CategoryGroupedBadges[], category) => {
        const filteredBadges = category.badges.reduce((badges: IBadge[], badge) => {
          if (fuzzyMatch(badge.name, searchTerm)) {
            badges.push(badge)
          }
          return badges
        }, [])
        if (filteredBadges.length > 0) {
          result.push({
            ...category,
            badges: filteredBadges
          })
        }
        return result
      }, [])
    }
    return filteredContent
  }

  const filterByNotExisting = (groupedBadges: CategoryGroupedBadges[]) => {
    let filteredContent = groupedBadges
    if (currentBadges.length > 0) {
      filteredContent = groupedBadges.reduce((result: CategoryGroupedBadges[], category) => {
        const filteredBadges = category.badges.reduce((badges: IBadge[], badge) => {
          if (!currentBadges.find(entry => entry.id === badge.id)) {
            badges.push(badge)
          }
          return badges
        }, [])
        if (filteredBadges.length > 0) {
          result.push({
            ...category,
            badges: filteredBadges
          })
        }
        return result
      }, [])
    }
    return filteredContent
  }

  const filters = (groupedBadges, formValues) => {
    let filteredResult = groupedBadges
    filteredResult = filterByCategories(filteredResult, formValues.filteredCategories)
    filteredResult = filterBySearchTerm(filteredResult, formValues.searchTerm)
    filteredResult = filterByNotExisting(filteredResult)
    return filteredResult
  }

  const getFormattedBadges = (badgeIds: string[]) => {
    const formattedBadges = badgeIds.map(badgeId => {
      return badges?.find(badge => badge.id === parseInt(badgeId))
    })
    return formattedBadges
  }

  return (
    <Modal onRequestClose={onRequestClose} show={show}>
      <div className='fixed z-10 inset-0 flex flex-col items-center bg-mono-black'>
        <button
          className='flex items-center gap-2 bg-mono-gray my-6 px-6 py-4 rounded-full text-white hover:bg-white hover:text-black'
          onClick={() => onRequestClose(false)}
        >
          <XIcon className='w-6 h-6' />

          Fermer
        </button>

        <Formik
          initialValues={{
            searchTerm: '',
            filteredCategories: [],
            badges: []
          }}
          onSubmit={(values) => {
            onRequestClose(getFormattedBadges(values.badges))
          }}
        >
          {({ values }) => (
            <Form className='relative w-full grow flex flex-col bg-white rounded-tl-3xl rounded-tr-3xl overflow-hidden'>
              {/* Content */}

              <div className='relative z-10 w-full px-6 py-14 sm:py-24 grow overflow-y-scroll'>
                <div className='text-left max-w-large-container mx-auto'>
                  <div>
                    <div className='text-h2 text-mono-black'>
                      Ajouter un badge supplémentaire
                    </div>

                    <div className='mt-2 text-mono-lightgray'>
                      Recherchez et sélectionnez un ou plusieurs badges pour les attribuer à l'apprenant.
                    </div>

                    {/* Search Field */}

                    <FieldRow
                      fieldStyles='h-[72px] mt-8 sm:mt-12 px-6 border border-mono-separator rounded-3xl placeholder:text-placeholder'
                      id='searchTerm'
                      label=''
                      name='searchTerm'
                      placeholder='Rechercher un badge'
                    />
                  </div>

                  {/* Tags */}

                  {themes && (
                    <div className='flex gap-2 mt-6 mb-12 overflow-x-auto'>
                      {themes.map((theme, index) => (
                        <CategoryFilter
                          iconName={theme.icon.name}
                          iconUrl={theme.icon.url}
                          id={`category${index}`}
                          key={index}
                          label={theme.name}
                          name='filteredCategories'
                        />
                      ))}
                    </div>
                  )}

                  {/* Badges List */}

                  {filters(groupedBadges, values).map((category, index) => (
                    <div key={index}>
                      <div className={`flex items-center after:flex-1 after:h-px after:bg-mono-separator after:mt-0.5 after:ml-2 ${index > 0 ? 'mt-6' : ''}`}>
                        {category.name}
                      </div>

                      <div className='grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-4 mt-6'>
                        {category.badges.map(badge => (
                          <Badge
                            icon={badge.image.url}
                            id={badge.id}
                            key={badge.id}
                            label={badge.name}
                            name='badges'
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Footer */}

              <div className='relative shrink-0 z-20 w-full bg-white p-6 drop-shadow-regular'>
                <div className='flex items-center justify-between max-w-large-container mx-auto'>
                  <div className='text-left text-blue text-h4 max-w-[40%]'>
                    {values.badges.length}

                    {values.badges.length === 1 ? ' badge sélectionné' : ' badges sélectionnés'}
                  </div>

                  <Button type='submit'>Ajouter</Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default BadgeFinder
