import PageTitle from '@components/layout/page-title/page-title'
import Menu from '@components/menu'
import { EyeIcon, PlusCircleIcon } from '@heroicons/react/solid'
import { RoutesName } from '@navigation/routes-name'
import ActivityTable from '@screens/activities/activity-table'
import { Link } from 'react-router-dom'


const Activities = () => {
  const buttons = (
    <>
      <Link
        className='inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300'
        to={RoutesName.ACTIVITIES_TYPE}
      >
        <EyeIcon aria-hidden='true' className='-ml-0.5 mr-2 h-4 w-4' />

        Voir les activités type
      </Link>
      <Link
        className='inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        to={RoutesName.ACTIVITY_CREATE}
      >
        <PlusCircleIcon aria-hidden='true' className='-ml-0.5 mr-2 h-4 w-4' />

        {'Ajouter une activité'}
      </Link>
    </>
  )

  return (
    <div className='p-4'>
      <Menu />

      <PageTitle buttons={buttons} title={'Activités'} />

      <div className='pt-4'>
        <ActivityTable />
      </div>
    </div>
  )
}

export default Activities
