import TableFooter from '@components/table/table-footer'
import ParticipantTableHead from '@screens/participants/participant-table-head'
import ParticipantTableLoading from '@screens/participants/participant-table-loading'
import ParticipantTableRow from '@screens/participants/participant-table-row'
import { ParticipantTableProps } from '@screens/participants/participant-table.interfaces'
import { apiParticipationProfilePdf } from '@services/api/participations/participation-profile-pdf'
import Notifications from '@services/notifications'


const ParticipantTable = ({ participations, page, onChangePage, onLabelClick, itemPerPage }: ParticipantTableProps) => {
  const handleSeeProfileClick = async (participationId: number) => {
    try {
      const response = await apiParticipationProfilePdf(participationId)
      if (response) {
        const url = window.URL.createObjectURL(response)
        window.open(url, '__blank')
        window.URL.revokeObjectURL(url)
      }
    } catch (err) {
      Notifications.onError(new Error('Impossible de récupérer le CV du participant'))
    }
  }

  return <>
    <div className='overflow-x-auto'>
      <div className='inline-block min-w-full py-2'>
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <ParticipantTableHead onLabelClick={onLabelClick} />

            <tbody className='bg-white divide-y divide-gray-200'>
              {
                !participations && (
                  <ParticipantTableLoading smaller />
                )
              }

              {
                participations
                  ? participations?.results?.map((participation) => (
                    <ParticipantTableRow key={participation.id} onSeeProfileClick={handleSeeProfileClick} participation={participation} />
                  ))
                  : <ParticipantTableLoading />
              }
            </tbody>
          </table>

          <TableFooter
            gotoPage={onChangePage}
            itemPerPage={itemPerPage}
            meta={participations?.meta ?? null}
            page={page}
          />
        </div>
      </div>
    </div>
  </>
}

export default ParticipantTable
