import Button from '@components/button/fancy-button'
import CompletionHeader from '@components/completion-header/completion-header'
import ErrorPage from '@components/error-page'
import FieldRow from '@components/form/field-row'
import Infoblock from '@components/infoblock'
import { CalendarIcon, ClockIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import { CSSVariables } from '@interfaces/components/css-variables'
import { RoutesName } from '@navigation/routes-name'
import { ActivityRecommendationProps } from '@screens/activity-completion/activity.interfaces'
import { useApiActivityDetailed } from '@services/api/activities'
import { useActivityCommentStore } from '@services/stores/activity-global-comment'
import { renderDate, renderTime } from '@services/utils/date-utils'
import { stringifyError } from '@services/utils/error-utils'
import { Form, Formik } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const Activity = () => {
  const offset: CSSVariables = { '--negative-offset': '72px' }

  // ID de l'activité
  const { id: idParam } = useParams()
  const id = Number.parseInt(idParam ?? '', 10)
  const { data, error } = useApiActivityDetailed(id)

  // Navigation
  const navigate = useNavigate()

  // Comment localStorage
  const { comment, setComment } = useActivityCommentStore()

  // Form Values
  const [isLoading, setIsLoading] = useState(false)
  const formValues: ActivityRecommendationProps = {
    recommendation: comment
  }

  useEffect(() => {
    setComment('')
  }, [])

  const onSubmit = (values: ActivityRecommendationProps) => {
    setIsLoading(true)
    if (values.recommendation !== '') {
      setComment(values.recommendation)
    }
    setIsLoading(false)
    navigate(RoutesName.createActivityCompletion(id, RoutesName.ACTIVITY_COMPLETION_PARTICIPANTS))
  }

  if (error) {
    return (
      <ErrorPage
        message={stringifyError(error)}
        title={'Erreur lors du chargement de l\'activité'}
      />
    )
  }

  return (
    <div style={offset}>
      <CompletionHeader
        backLink={RoutesName.ACTIVITY_COMPLETION_INFORMATIONS}
        currentStep={2}
        description='Ajoutez une recommandation pour tous les participants.👇'
        title='Activité'
        totalSteps={4}
      />

      <div className='max-w-container mx-auto -mt-n-offset px-6 md:px-0'>
        {/* Activity Head */}

        <div className='flex flex-col'>
          <div className='flex flex-col sm:flex-row items-center gap-6 bg-white rounded-3xl'>
            <div className='sm:p-6 sm:pr-0'>
              <img alt='' className='w-full sm:w-24 sm:h-24 object-cover bg-blue rounded-t-3xl sm:rounded-3xl' src={data?.cover?.url} />
            </div>

            <div className='p-6 pt-0 sm:pt-6 sm:pl-0'>
              <div className='text-h3'>{data?.title}</div>

              <div className='flex items-center mt-2 text-blue text-sm'>
                <CalendarIcon className='w-4 h-4 mr-1' />

                <div>{data?.start_at ? renderDate(data.start_at) : ''}</div>

                <ClockIcon className='w-4 h-4 ml-3 mr-1' />

                <div>
                  {`${data?.start_at ? renderTime(data.start_at) : ''} - `}

                  {data?.start_at ? renderTime(moment(data.start_at).add(data.duration, 'hours').toISOString()) : ''}
                </div>
              </div>

              <div className='flex items-center mt-2 text-blue text-sm'>
                <LocationMarkerIcon className='w-4 h-4 mr-1' />

                <div>{data?.address}</div>
              </div>
            </div>
          </div>
        </div>

        {/* Form */}

        <div className='mt-11'>
          <Formik
            initialValues={formValues}
            onSubmit={onSubmit}
          >
            <Form>
              <FieldRow
                fieldStyles='h-[72px] mt-2 px-6 rounded-3xl focus:ring-lightgray text-placeholder'
                id='recommendation'
                label='Ajouter une recommandation'
                labelStyles='text-h3 text-mono-black'
                name='recommendation'
                placeholder='Votre recommandation...'
              />

              <Infoblock>
                S'il y avait plusieurs participants, cette recommandation sera attribuée à tous les participants. Vous pourrez également rédiger une recommandation personnalisée pour chacun d'entre eux lors de l'attribution des badges.
              </Infoblock>

              <Button
                className='mt-12'
                isLoading={isLoading}
                type='submit'
              >
                Étape suivante
              </Button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default Activity
