import { ImagePlaceholderProps } from '@components/image/image-placeholder/image-placeholder.interfaces'
import classNames from 'classnames'

const ImagePlaceholder = ({ className, text }: ImagePlaceholderProps) => {
  return (
    <div className={classNames('bg-gray-200 flex justify-center items-center', className)}>
      <span className='text-sm font-sans font-semibold'>
        {text ?? 'N/A'}
      </span>
    </div>
  )
}

export default ImagePlaceholder
