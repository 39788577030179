import { BadgeProps } from '@components/form/badge-collection/badge.interfaces'
import BadgeToggle from '@components/form/badge-toggle'
import FieldRow from '@components/form/field-row'
import { ParticipationFormProps } from '@screens/activity-completion/badges.interfaces'
import { useFormikContext } from 'formik'
import { useEffect, useMemo, useState } from 'react'

const Badge = ({ badge, index }: BadgeProps) => {
  // Checkbox state
  const badgeId = `badges[${index}].checkbox`
  const { values: { badges } } = useFormikContext<ParticipationFormProps>()

  const levelOptions = useMemo(() => {
    if (badge?.badge_levels?.length > 0) {
      return badge?.badge_levels.map(({ id, title, level }) =>
        ({ value: id, label: `Niveau ${level} - ${title}` })
      )
    }

    return []
  }, [badge])
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (badges[index] !== undefined) {
      setChecked(!badges[index].checkbox)
    }
  }, [badges])

  return (
    <div className='flex flex-col bg-white rounded-3xl'>
      {/* Badge details */}

      <div className='flex items-center gap-4 p-6'>
        {badge.imageUrl &&
          <img alt='' className='shrink-0 w-20 h-20' src={badge.imageUrl} />
        }

        {badge.name &&
          <div className='font-bold'>{badge.name}</div>
        }
      </div>

      {/* Toggle */}

      <BadgeToggle badgeId={badgeId} />

      {/* Comment */}

      <div className={`flex flex-col gap-4 p-6 border-t border-mono-separator ${checked ? 'hidden' : ''}`}>
        <FieldRow
          as='textarea'
          fieldStyles='mt-2 p-0 border-none placeholder:text-mono-lightgray'
          id='badge_comment'
          label='Ajouter un commentaire'
          labelStyles='font-bold text-mono-gray'
          name={`badges[${index}].comment`}
          placeholder='Votre commentaire...'
        />

        {levelOptions?.length > 0 && (
          <FieldRow
            as='select'
            label='Sélectionner un niveau'
            name={`badges[${index}].badge_level`}
          >
            <option value=''>Veuillez sélectionner un niveau</option>

            {levelOptions.map(({ value, label }) => (
              <option key={`badge-level-${badge.id}-${value}`} value={value}>{label}</option>
            ))}
          </FieldRow>
        )}
      </div>
    </div>
  )
}

export default Badge
