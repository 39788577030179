import { NewParticipantModalProps } from '@components/activity/activity-form/participant-manager/new-participant-modal.interfaces'
import Modal from '@components/modal'
import { XIcon } from '@heroicons/react/outline'
import { apiCreateUser } from '@services/api/user'
import Notifications from '@services/notifications'
import classNames from 'classnames'
import { memo, useRef, useState } from 'react'

const inputClasses = 'form-input mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm rounded-md'
const labelClasses = 'block text-sm font-medium text-gray-700'

function isValidEmail (email) {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
}

const NewParticipantModal = ({ isOpen, onClose }: NewParticipantModalProps) => {
  const [email, setEmail] = useState('')
  const [startedTyping, setStartedTyping] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const closeButtonRef = useRef(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!startedTyping) {
      setStartedTyping(true)
    }
    setIsValid(!!isValidEmail(e.target.value))
    setEmail(e.target.value)
  }

  const handleClose = () => {
    onClose()
    setEmail('')
    setStartedTyping(false)
    setIsValid(false)
  }

  const handleClick = async () => {
    if (!isValid || !email) {
      return
    }

    return await apiCreateUser({ email })
      .then(createdUser => {
        Notifications.addSuccessMessage('Utilisateur créé avec succès')
        handleClose()
        return createdUser
      })
      .catch(err => {
        Notifications.onError(err)
        throw err
      })
  }

  return (
    <Modal initialFocus={closeButtonRef} onRequestClose={handleClose} show={isOpen}>
      <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6'>
        <button className='absolute top-0 right-0 pt-5 pr-5' onClick={onClose} ref={closeButtonRef}>
          <XIcon className='h-5 w-auto' />
        </button>

        <h3>Créer un utilisateur</h3>

        <div className='mt-5'>
          <label className={labelClasses} htmlFor='new-participant-email'>Adresse email</label>

          <input
            className={classNames(
              inputClasses,
              (isValid || !startedTyping) ? 'border-gray-300' : 'border-red-500'
            )}
            id='new-participant-email'
            onChange={handleChange}
            placeholder='Adresse email'
            type='text'
            value={email}
          />

          <button
            className={classNames(
              'mt-3 w-full text-center flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ',
              isValid ? 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' : 'bg-indigo-300 cursor-not-allowed'
            )}
            onClick={handleClick}
            type='button'
          >
            Créer le compte
          </button>
        </div>
      </div>
    </Modal>

  )
}

export default memo(NewParticipantModal)
