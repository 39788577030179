import LoadingBox from '@components/loading/loading-box'
import { BadgeTableLoadingProps } from '@screens/badges/badge-table-loading.interfaces'

const BadgeTableLoading = ({ smaller = false }: BadgeTableLoadingProps) => (
  <tr className='bg-gray-50'>
    <td colSpan={8}>
      <div className={smaller ? 'py-4 h-16' : 'py-16 h-56'}>
        <LoadingBox />
      </div>
    </td>
  </tr>
)

export default BadgeTableLoading
