import { UserTableRowProps } from '@screens/users/user-table-row.interfaces'
import { memo, useMemo } from 'react'

const ParticipantTableRow = ({ user }: UserTableRowProps) => {
  const fullName = useMemo(() => {
    if (user?.firstname && user?.lastname) {
      return `${user.firstname} ${user.lastname.toUpperCase()}`
    }
    return 'N/A'
  }, [user?.firstname, user?.lastname])

  return (
    <tr key={user.id}>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {user.id}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {fullName}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {user.roles?.[0]?.name}
        </div>
      </td>

      {/* <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          <button
            className={classNames(
              'flex items-center py-0.5',
              isCvAvailable ? 'text-indigo-600 hover:text-indigo-900' : 'text-gray-600'
            )}
            disabled={!isCvAvailable}
            onClick={handleClick}
            title={isCvAvailable ? 'Voir le CV' : 'CV indisponible car le profil de l\'utilisateur n\'est pas complet'}
          >
            {isCvAvailable ? <EyeIcon className='h-5 mr-2 w-auto' /> : <EyeOffIcon className='h-5 mr-2 w-auto' />}

            <span>{isCvAvailable ? 'Voir le CV' : 'CV indisponible'}</span>
          </button>

        </div>
      </td> */}
    </tr>
  )
}

export default memo(ParticipantTableRow)
