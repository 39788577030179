import { CheckIcon } from '@heroicons/react/solid'
import { Filters } from '@interfaces/components/filters'
import { ParticipantTableFilterProps } from '@screens/participants/participant-table-filter.interfaces'
import classNames from 'classnames'

const getFilterTranslation = (filter: Filters) => {
  switch (filter) {
    case Filters.Coming:
      return 'À venir'
    case Filters.Finished:
      return 'Terminée'
    case Filters.InProgress:
      return 'En cours'
    default:
      return 'Tous'
  }
}

const ParticipantTableFilter = ({ filters, selectedFilter, onClick }: ParticipantTableFilterProps) => {
  return (
    <div className='flex items-center'>
      <p>Filtrer par status d'activité :</p>

      <div className='flex items-center ml-3'>
        {filters.map((filter, index) => {
          const isSelected = filter === selectedFilter

          return (
            <button
              className={classNames(
                'mr-3 px-4 py-2 border text-sm font-medium flex items-center',
                isSelected
                  ? 'bg-indigo-50 border-indigo-500 text-indigo-600'
                  : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
              )}
              key={`filter-${filter}-${index}`}
              onClick={() => onClick(filter)}>
              <span>{getFilterTranslation(filter)}</span>

              {' '}

              {isSelected && <CheckIcon className='ml-2 h-5 w-5 text-indigo-600' />}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default ParticipantTableFilter
