import { ZoneType } from '@interfaces/api/zone'

export const getZoneTypeLabel = (type: ZoneType): string => {
  switch (type) {
    case 'nation':
      return 'Nation'
    case 'department':
      return 'Département'
    case 'region':
      return 'Region'
    default:
      return type
  }
}
