import { UserTableHeadProps } from '@screens/users/user-table-head.interfaces'

const UserTableHead = ({ onLabelClick }: UserTableHeadProps) => (
  <thead className='bg-gray-50 text-left text-sm font-semibold text-gray-900'>
    <tr>
      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('id')}
        >
          {'ID'}
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-default font-sans font-semibold'}
        >
          Prénom NOM
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('roles.id')}
        >
          Role
        </button>
      </th>
    </tr>
  </thead>
)

export default UserTableHead
