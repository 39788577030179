import Toast from '@components/toast/toast'

const End = () => {
  return (
    <Toast>
      <h2 className='text-h2 text-mono-black font-semibold'>Merci !</h2>

      <p className='mt-2'>Vos commentaires et badges attribués seront comuniqués aux apprenants. Ils pourront également les ajouter à leur Open CV. 💪</p>

      <p className='mt-6'>Merci pour eux, c'est précieux pour les aider à construire leur parcours professionnel !</p>
    </Toast>
  )
}

export default End
