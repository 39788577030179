import styles from '@components/layout/completion-tunnel/styles.module.css'
import { ChildrenProps } from '@interfaces/components/children'

const Layout = ({ children }: ChildrenProps) => {
  return (
    <div className={styles.wrapper}>
      {children}
    </div>
  )
}

export default Layout
