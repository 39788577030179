import { FormatName, Image } from '@interfaces/api/image'

export const extractImageUrl = (image: Image, preferredSize?: FormatName): string | null => {
  const priority: FormatName[] = ['thumbnail', 'small', 'medium', 'large']

  if (preferredSize && image.formats) {
    if (image.formats[preferredSize]) {
      return image.formats[preferredSize].url
    }

    let index = priority.indexOf(preferredSize)
    if (index >= 0) {
      while (index < priority.length) {
        const format = priority[index]
        if (image.formats[format]) {
          return image.formats[format].url
        }
        index++
      }
    }
  }

  if (image.url) {
    return image.url
  }

  return null
}
