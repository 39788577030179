import ImageElement from '@components/image/image-element'
import { ImageLibraryContentProps } from '@components/image/image-library-modal/image-library-content.interfaces'
import LoadingBox from '@components/loading/loading-box'
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'
import { Image } from '@interfaces/api/image'
import { useApiImages } from '@services/api/images'
import Notifications from '@services/notifications'
import classNames from 'classnames'
import { KeyboardEvent, useCallback, useEffect, useState } from 'react'

const ITEM_BY_PAGE = 15

const ImageLibraryContent = ({ onSelect, onSubmit }: ImageLibraryContentProps) => {
  const [page, setPage] = useState(1)

  const { data, error } = useApiImages({
    _start: (page - 1) * ITEM_BY_PAGE,
    _limit: ITEM_BY_PAGE,
    _sort: 'id:DESC'
  })

  useEffect(() => {
    if (error) {
      Notifications.onError(error)
    }
  }, [error])

  const onKeyPress = useCallback((e: KeyboardEvent) => {
    if (['Enter', ' '].includes(e.key)) {
      onSubmit()
    }
  }, [onSubmit])

  if (!data) {
    return (
      <div className='flex justify-center items-center h-48 py-8'>
        <LoadingBox />
      </div>
    )
  }

  return (
    <>
      <ul className='grid grid-cols-4 lg:grid-cols-5 gap-x-1 gap-y-3 mt-6'>
        {data.map((image: Image) => (
          <li className='flex flex-col p-2 max-h-64' key={image.id}>
            <button
              className='grow flex items-center bg-gray-100 rounded-lg item-center justify-center focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden hover:opacity-75'
              onDoubleClick={onSubmit}
              onFocus={() => onSelect(image)}
              onKeyPress={onKeyPress}
              type='button'
            >
              <ImageElement
                className='max-h-full max-w-full'
                image={image}
                preferredSize={'thumbnail'}
              />
            </button>

            <div>
              <p className='mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none'>
                {image.name}
              </p>

              <p className='block text-sm font-medium text-gray-500 pointer-events-none'>
                {`${image.size} ko`}
              </p>
            </div>
          </li>
        ))}
      </ul>

      <div className='border-t border-gray-200 px-4 flex items-center justify-between pb-3'>
        <button
          className={classNames(
            'border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300',
            {
              'opacity-50': (page <= 1)
            }
          )}
          disabled={page <= 1}
          onClick={() => setPage(page - 1)}
        >
          <ArrowNarrowLeftIcon aria-hidden='true' className='mr-3 h-5 w-5 text-gray-400' />

          {'Précédent'}
        </button>

        <button
          className={classNames(
            'border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300',
            {
              'opacity-50': (data.length < ITEM_BY_PAGE)
            }
          )}
          disabled={data.length < ITEM_BY_PAGE}
          onClick={() => setPage(page + 1)}
        >
          {'Suivant'}

          <ArrowNarrowRightIcon aria-hidden='true' className='ml-3 h-5 w-5 text-gray-400' />
        </button>
      </div>
    </>
  )
}

export default ImageLibraryContent
