import Button from '@components/button/button'
import { ButtonProps } from '@components/button/button.interfaces'
import classNames from 'classnames'
import { forwardRef } from 'react'

const DangerButton = ({ className, spinnerColor = 'text-white', ...props }: ButtonProps, ref) => {
  return (
    <Button
      {...props}
      className={classNames(
        'bg-red-600 text-white hover:bg-red-700',
        className
      )}
      ref={ref}
      spinnerColor={spinnerColor}
    />
  )
}

export default forwardRef<HTMLButtonElement, ButtonProps>(DangerButton)
