import { BadgeCheckboxesProps } from '@components/activity/activity-form/badge-checkboxes/badge-checkboxes.interfaces'
import BadgeFavorite from '@components/activity/activity-form/badge-checkboxes/badge-favorite'
import ImageElement from '@components/image/image-element'
import ImagePlaceholder from '@components/image/image-placeholder'
import { SearchIcon } from '@heroicons/react/solid'
import { AdminBadge, AdminBadges } from '@interfaces/api/admin-badge'
import { Badge } from '@interfaces/api/badge'
import { useApiAdminBadges } from '@services/api/admin-badges'
import { useApiBadges } from '@services/api/badges'
import Notifications from '@services/notifications'
import { useCallback, useEffect, useState } from 'react'

const BadgeCheckboxes = ({
  field: { name, value },
  form: { setFieldValue }
}: BadgeCheckboxesProps) => {
  const [favorites, setFavorites] = useState<AdminBadges>([])
  const [search, setSearch] = useState('')
  const { data, error } = useApiBadges()
  const { data: adminBadges, error: adminBadgesError, isValidating: adminBadgesIsValidating } = useApiAdminBadges()

  useEffect(() => {
    if (error) {
      Notifications.onError(error)
    }
  }, [adminBadgesError])

  useEffect(() => {
    if (adminBadgesError) {
      Notifications.onError(adminBadgesError)
    }
  }, [adminBadgesError])

  useEffect(() => {
    if (adminBadges && !adminBadgesIsValidating) {
      setFavorites(adminBadges)
    }
  }, [adminBadges, adminBadgesIsValidating])

  const onFavorite = useCallback((adminBadge: AdminBadge) => {
    setFavorites(prevState => [...prevState, adminBadge])
  }, [])

  const onUnFavorite = useCallback((adminBadge: AdminBadge) => {
    setFavorites(prevState => prevState.filter(f => f !== adminBadge))
  }, [])

  const toggleBadge = useCallback((badge: Badge) => {
    const oldValue = value ?? []

    // Try to remove badge from field value
    const newValue = oldValue.filter(({ id }) => id !== badge.id)

    // If nothing changed, so we add it
    if (newValue.length === oldValue.length) {
      setFieldValue(name, [
        ...newValue,
        badge
      ])
    } else {
      setFieldValue(name, newValue)
    }
  }, [setFieldValue, name, value])

  let list = data?.results ?? value

  if (search) {
    list = list.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
  }

  const favoriteList: Badge[] = []
  const notFavoriteList: Badge[] = []
  list.forEach((badge) => {
    const adminBadge = favorites.find(({ badge: { id } }) => id === badge.id) ?? null
    if (adminBadge) {
      favoriteList.push(badge)
    } else {
      notFavoriteList.push(badge)
    }
  })
  list = [...favoriteList, ...notFavoriteList]

  return (
    <div>
      <div className='mt-1 flex rounded-md shadow-sm'>
        <div className='relative flex items-stretch flex-grow focus-within:z-10'>
          <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
            <SearchIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />
          </div>

          <input
            className='focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300'
            name='search-badge'
            onChange={(e) => setSearch(e.target.value)}
            placeholder='Rechercher un badge'
            type='search'
            value={search}
          />
        </div>
      </div>

      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='shadow ring-1 ring-black ring-opacity-5 md:rounded-lg h-96 overflow-y-scroll'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50'>
              <tr>
                <th className='py-2.5 pl-3 pr-2 text-left text-sm font-semibold text-gray-900' scope='col'>
                  {'Nom'}
                </th>

                <th className='px-2 py-2.5 text-left text-sm font-semibold text-gray-900' scope='col'>
                  {'Thématique'}
                </th>

                <th className='px-2 py-2.5 text-center text-sm font-semibold text-gray-900' scope='col'>
                  {'Favoris'}
                </th>

                <th className='px-2 py-2.5 text-center text-sm font-semibold text-gray-900' scope='col'>
                  {'Ajouter'}
                </th>
              </tr>
            </thead>

            <tbody className='divide-y divide-gray-200 bg-white'>
              {list.map((badge) => {
                const checked = !!value.find(({ id }) => id === badge.id)
                const adminBadge = favorites.find(({ badge: { id } }) => id === badge.id) ?? null

                return (
                  <tr key={badge.id}>
                    <td className='py-2 pl-3 pr-2 text-base font-medium text-gray-900'>
                      <div className='flex items-center'>
                        <div className='w-8 h-8 flex-shrink-0 flex items-center justify-center'>
                          <ImageElement
                            className='max-h-8 w-auto'
                            image={badge.image}
                            placeholder={<ImagePlaceholder className='h-8 w-8' />}
                            preferredSize={'thumbnail'}
                          />
                        </div>

                        <span className='ml-2'>{badge.name}</span>
                      </div>
                    </td>

                    <td className='whitespace-nowrap px-2 py-2 text-sm text-gray-500'>
                      {badge.theme?.name}
                    </td>

                    <td className='px-2 py-2 text-center'>
                      <BadgeFavorite
                        adminBadge={adminBadge}
                        badge={badge}
                        onFavorite={onFavorite}
                        onUnFavorite={onUnFavorite}
                      />
                    </td>

                    <td className='px-2 py-2 text-center'>
                      <input
                        aria-describedby={`badge-${badge.id}`}
                        checked={checked}
                        className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                        id={`badge-${badge.id}`}
                        name='badges'
                        onChange={() => toggleBadge(badge)}
                        type='checkbox'
                      />
                    </td>
                  </tr>
                )
              })}

              {list.length === 0 && (
                <tr>
                  <td className='py-10 text-center font-medium text-gray-800' colSpan={4}>
                    <span>Pas de résultats</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default BadgeCheckboxes
