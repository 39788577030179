import PrimaryButton from '@components/button/primary-button'
import PageTitle from '@components/layout/page-title/page-title'
import UserForm from '@components/user/user-form/user-form'
import { UserFormValues } from '@components/user/user-form/user-form.interfaces'
import { SaveIcon } from '@heroicons/react/solid'
import { AdminUser } from '@interfaces/api/user'
import { RoutesName } from '@navigation/routes-name'
import { apiCreateAdminUser } from '@services/api/user'
import Notifications from '@services/notifications'
import { FormikProps } from 'formik/dist/types'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const UserCreate = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  // Bound formik props from children
  let formikProps: FormikProps<UserFormValues> | null = null
  const bindFormikProps = (props: FormikProps<UserFormValues>) => {
    formikProps = props
  }

  // On Save button, we submit form
  const onSaveButtonClick = async () => {
    if (formikProps) {
      const errors = await formikProps.validateForm()
      if (Object.keys(errors).length > 0) {
        Notifications.addWarningMessage('Le formulaire est invalide')
      }

      await formikProps.submitForm()
    }
  }

  // On form submit, save the activity
  const onFormSubmit = useCallback(async (values: UserFormValues): Promise<AdminUser> => {
    setIsSubmitting(true)
    return await apiCreateAdminUser(values)
      .then(user => {
        Notifications.addSuccessMessage('Nouvel utilisateur créé')
        setTimeout(() => navigate(RoutesName.USERS), 50)

        return user
      })
      .catch(err => {
        setIsSubmitting(false)
        Notifications.onError(err)
        throw err
      })
  }, [])

  const buttons = <>
    <PrimaryButton
      className='w-auto text-md'
      disabled={isSubmitting}
      isLoading={isSubmitting}
      onClick={onSaveButtonClick}
    >
      <SaveIcon aria-hidden='true' className='-ml-0.5 mr-2 h-4 w-auto' />

      {'Enregistrer'}
    </PrimaryButton>
  </>

  return (
    <div className='p-4'>
      <PageTitle
        backRoute={RoutesName.USERS}
        buttons={buttons}
        title={'Créer un utilisateur'}
      />

      <div className='p-4 pb-10'>
        <UserForm bindFormikProps={bindFormikProps} onFormSubmit={onFormSubmit} />
      </div>
    </div>
  )
}

export default UserCreate
