import Button from '@components/button/fancy-button'
import CompletionHeader from '@components/completion-header/completion-header'
import ErrorPage from '@components/error-page'
import FieldRow from '@components/form/field-row'
import { Helper } from '@interfaces/api/helper'
import { RoutesName } from '@navigation/routes-name'
import { useApiActivityDetailed } from '@services/api/activities'
import { apiHelperUpdate } from '@services/api/helpers/helper'
import Notifications from '@services/notifications'
import { stringifyError } from '@services/utils/error-utils'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const Informations = () => {
  // ID de l'activité
  const { id: idParam } = useParams()
  const id = Number.parseInt(idParam ?? '', 10)
  const { data: activity, error } = useApiActivityDetailed(id)

  // Form state variables
  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues] = useState<Helper>()
  useEffect(() => {
    if (activity?.helper) {
      setFormValues(activity?.helper)
    }
  }, [activity])

  // Navigation
  const navigate = useNavigate()

  const onSubmit = (values: Helper) => {
    setIsLoading(true)
    if (activity?.helper) {
      apiHelperUpdate(id, {
        name: values.name,
        bio: values.bio
      })
        .catch(error => {
          Notifications.onError(error)
          throw error
        })
        .finally(() => {
          setIsLoading(false)
          navigate(RoutesName.createActivityCompletion(id, RoutesName.ACTIVITY_COMPLETION_ACTIVITY))
        })
    }
  }

  if (error) {
    return (
      <ErrorPage
        message={stringifyError(error)}
        title={'Erreur lors du chargement de l\'activité'}
      />
    )
  }

  return (
    <div>
      <CompletionHeader
        currentStep={1}
        description='Indiquez votre nom, votre prénom, votre entreprise et votre poste. 👇'
        title='Informations'
        totalSteps={4}
      />

      {activity?.helper && formValues &&
        <div className='max-w-container mx-auto -mt-n-offset px-6 md:px-0'>
          <div className='flex flex-col pt-12'>
            <Formik
              initialValues={formValues}
              onSubmit={onSubmit}>
              <Form>
                <div className='flex flex-col gap-6'>
                  <FieldRow
                    fieldStyles='h-[72px] mt-2 px-6 rounded-3xl focus:ring-lightgray text-placeholder'
                    id='name'
                    label='Nom complet'
                    labelStyles='text-h3 text-mono-black'
                    name='name'
                    placeholder='Votre nom complet'
                  />

                  <FieldRow
                    fieldStyles='h-[72px] mt-2 px-6 rounded-3xl focus:ring-lightgray text-placeholder'
                    id='bio'
                    label='Poste'
                    labelStyles='text-h3 text-mono-black'
                    name='bio'
                    placeholder='Votre poste'
                  />
                </div>

                <Button
                  className='mt-12'
                  isLoading={isLoading}
                  type='submit'
                >
                  Étape suivante
                </Button>
              </Form>
            </Formik>
          </div>
        </div>
      }
    </div>
  )
}

export default Informations
