import { Images } from '@interfaces/api/image'
import { axiosGet } from '@services/api/axios'
import { loadOnceSwrConfig } from '@services/api/swr-config'
import qs from 'qs'
import useSwr, { Fetcher } from 'swr'

type Props = Record<string, unknown>

export const useApiImages = (params: Props) => {
  const queryString = qs.stringify(params)

  const { data, error } = useSwr<Images>(
    `/upload/files?${queryString}`,
    axiosGet as Fetcher<Images>,
    loadOnceSwrConfig
  )

  return {
    data,
    error
  }
}
