import { ProgressionStepsProps } from '@components/progression-steps/progression-steps.interfaces'
import Step from '@components/progression-steps/step'

const ProgressionSteps = ({ current, total }: ProgressionStepsProps) => {
  const steps: React.ReactElement[] = []
  for (let i = 0; i < total; i++) {
    steps.push(<Step isFirst={i === 0} isPast={i < current} key={i} />)
  }

  return (
    <div className='hidden sm:flex items-center mt-6'>
      {steps}
    </div>
  )
}

export default ProgressionSteps
