import { ActivityStatusProps } from '@components/activity/activity-status.interfaces'

const ActivityStatus = ({ status }: ActivityStatusProps) => {
  if (status === 'open') {
    return (
      <span className='px-2 inline-flex text-xs leading-5 font-semibold bg-teal-100 text-teal-800 border border-teal-500'>
        {'Ouvert'}
      </span>
    )
  }

  return (
    <span className='px-2 inline-flex text-xs leading-5 font-semibold bg-red-100 text-red-800 border border-red-500'>
      {'Clôturé'}
    </span>
  )
}

export default ActivityStatus
