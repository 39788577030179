import { HelperSelectItemsProps } from '@components/activity/activity-form/helper-select/helper-select-items.interfaces'
import SelectError from '@components/form/select-error'
import SelectLoading from '@components/form/select-loading'
import ImageElement from '@components/image/image-element'
import ImagePlaceholder from '@components/image/image-placeholder'
import { Listbox } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/solid'
import { useApiHelpers } from '@services/api/helpers'
import { stringifyError } from '@services/utils/error-utils'
import classNames from 'classnames'
import { memo } from 'react'

const HelperSelectItems = ({ value }: HelperSelectItemsProps) => {
  const { data, error } = useApiHelpers()

  if (error) {
    return (
      <SelectError message={stringifyError(error)} />
    )
  }

  if (!data) {
    return <SelectLoading />
  }

  return (
    <div className='divide-y divide-gray-200'>
      {data.map(helper => (
        <Listbox.Option
          key={helper.id}
          value={helper}
        >
          {({ active, selected }) => (
            <div className={classNames(
              'flex items-center justify-between px-4 py-2',
              active ? 'text-white bg-indigo-600' : 'text-gray-900'
            )}>
              <div className='flex items-center'>
                <div className='flex-shrink-0 h-8 w-8'>
                  <ImageElement
                    className='h-8 w-8 rounded-full'
                    image={helper.picture}
                    placeholder={<ImagePlaceholder className='h-8 w-8 rounded-full' text={'?'} />}
                    preferredSize={'thumbnail'}
                  />
                </div>

                <div className='ml-4'>
                  <div className='text-sm font-medium'>
                    {helper.name}
                  </div>
                </div>
              </div>

              {(selected || value?.id === helper.id) &&
                <CheckIcon aria-hidden='true' className='h-5 w-5 text-green-600' />
              }
            </div>
          )}
        </Listbox.Option>
      ))}
    </div>
  )
}

export default memo(HelperSelectItems)
