import { setApiURL, setAuthorizationBearer } from '@services/api/axios'
import { useAuthStore } from '@services/stores/auth'
import fr from 'date-fns/locale/fr'
import moment from 'moment'
import 'moment/locale/fr'
import { useEffect, useState } from 'react'
import { registerLocale } from 'react-datepicker'

export const useStartup = () => {
  const [isReady, setIsReady] = useState(false)
  const [error, setError] = useState('')
  const { token, setToken } = useAuthStore()

  useEffect(() => {
    moment.locale('fr')
    registerLocale('fr', fr)

    setApiURL(process.env.REACT_APP_API_URL)

    // If token assigned from query params, we use it
    const searchParams = new URLSearchParams(document.location.search)
    const queryToken = searchParams.get('token')
    if (queryToken) {
      setToken(queryToken)
    } else {
      setIsReady(true)
    }
  }, [])

  useEffect(() => {
    setAuthorizationBearer(token)
    if (token) {
      setIsReady(true)
    }
    setError(token ? '' : 'Pas de token défini')
  }, [token])

  return {
    isReady,
    error
  }
}
