import { ActivityFormValues } from '@components/activity/activity-form/activity-form-interfaces'
import { ActivitySavePayload } from '@services/api/activities/activity-save'

export const convertActivityFormValuesToActivitySavePayload = ({
  start_at: startAt,
  duration,
  participants_limit: participantsLimit,
  theme,
  zone,
  helper,
  badges,
  cover,
  duplicable,
  ...values
}: ActivityFormValues): ActivitySavePayload => ({
  ...values,
  start_at: startAt || null,
  participants_limit: participantsLimit || null,
  duration: duration || null,
  theme: theme?.id ?? null,
  zone: zone?.id ?? null,
  helper: helper?.id ?? null,
  cover: cover?.id ?? null,
  badges: badges ? badges.map(({ id }) => id) : [],
  duplicable: duplicable ?? false
})
