import { Badges } from '@interfaces/api/badge'
import { axiosGet } from '@services/api/axios'
import { loadOnceSwrConfig } from '@services/api/swr-config'
import qs from 'qs'
import useSwr, { Fetcher } from 'swr'

type Props = Record<string, unknown>

export const useApiBadges = (params?: Props) => {
  const queryString = qs.stringify(params ?? { _sort: 'name:asc', _limit: -1 })

  const { data, error } = useSwr<Badges>(
    `/external-app-plugin/badges?${queryString}`,
    axiosGet as Fetcher<Badges>,
    loadOnceSwrConfig
  )

  return {
    data,
    error
  }
}
