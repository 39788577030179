import { Profile } from '@interfaces/api/profile'
import { axiosGet } from '@services/api/axios'
import { loadOnceSwrConfig } from '@services/api/swr-config'
import qs from 'qs'
import useSwr, { Fetcher } from 'swr'

type Props = Record<string, unknown>

export const useApiProfileSearch = (params: Props, preventFetch?: boolean) => {
  const queryString = qs.stringify(params)

  const { data, error, mutate } = useSwr<Profile[]>(preventFetch
    ? null
    : `/external-app-plugin/users?${queryString}`,
  axiosGet as Fetcher<Profile[]>,
  loadOnceSwrConfig
  )

  return {
    data,
    error,
    mutate
  }
}
