import Spinner from '@components/loading/spinner'

const LoadingBox = () => {
  return (
    <div className='flex flex-col justify-center items-center h-full'>
      <Spinner className='h-full w-auto' />
    </div>
  )
}

export default LoadingBox
