import Button from '@components/button/fancy-button'
import CompletionHeader from '@components/completion-header/completion-header'
import ParticipationBadgesEditor from '@components/participation-badges-editor'
import ParticipationHead from '@components/participation-head'
import { Badge } from '@interfaces/api/badge'
import { Participation, ParticipationBadge } from '@interfaces/api/participation'
import { CSSVariables } from '@interfaces/components/css-variables'
import { RoutesName } from '@navigation/routes-name'
import { AppendBadgePayload, ParticipationFormProps } from '@screens/activity-completion/badges.interfaces'
import { useApiActivityDetailed } from '@services/api/activities/activity-detailed'
import { apiParticipantBadgesUpdate } from '@services/api/participations/participation'
import Notifications from '@services/notifications'
import { useActivityCommentStore } from '@services/stores/activity-global-comment'
import { useParticipantsCommentsStore } from '@services/stores/activity-participants-comments'
import { extractImageUrl } from '@services/utils/image-utils'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

/*
[Badges Screen]
| Récupère les infos détaillées de l'activité (ActivityDetailed)
| contenant la liste des participants et leurs badges.
|
|-> [ParticipationHead]
|   Affiche les infos du participant et permet d'éditer une recommendation globale
|
|-> [ParticipationBadgesEditor]
|   Permet d'éditer et ajouter des badges à une participation.
|
|-> [Prev | Next]
|   Sauvegarde les badges et commentaires en appellant
|   apiParticipantBadgesUpdate() sur la participation en cours d'affichage.
|   Modifie la participation passée en props à ParticipationBadgesEditor.
*/

const Badges = () => {
  const offset: CSSVariables = { '--negative-offset': '112px' }

  const navigate = useNavigate()

  // ID de l'activité
  const { id: idParam } = useParams()
  const id = Number.parseInt(idParam ?? '', 10)

  // Stored recommendations (global activity reco, participant specific reco)
  const { comment: globalComment } = useActivityCommentStore()
  const { comments: participantsComments } = useParticipantsCommentsStore()

  // Form values
  const [current, setCurrent] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [participations, setParticipations] = useState<Participation[]>([])
  const [formValues, setFormValues] = useState<ParticipationFormProps>({ badges: [], comment: '' })
  const [editorBadges, setEditorBadges] = useState<ParticipationBadge[]>([])

  // Récupération de la liste des participants et de leurs badges
  const { data: activity, mutate: mutateActivity } = useApiActivityDetailed(id)

  useEffect(() => {
    if (activity) {
      // Filtre les participants absents de la liste
      setParticipations(activity.participations.filter(participation => (
        participation.status === 'present'
      )))
    }
  }, [activity])

  const getBadgeFormProps = (badge: ParticipationBadge | Badge, participantId: number) => {
    /*
      Remplit le commentaire en utilisant en priorité
        - Lors d'un reload des badges, le commentaire déjà existant dans la réponse de l'api
        - Le commentaire général spécifique au participant
        - Le commentaire global de l'activité
    */
    let badgeComment = ''
    if ('comment' in badge) {
      badgeComment = badge.comment ?? ''
    } else {
      badgeComment = getDefaultReco(participantId)
    }

    let badgeLevel
    if ('badge_level' in badge) {
      badgeLevel = badge.badge_level
    }

    return {
      id: badge.id,
      checkbox: true,
      comment: badgeComment,
      badge_level: badgeLevel
    }
  }

  const getBadgeEditorProps = (badge: Badge & {badge_level?: string}) => {
    return {
      id: badge.id,
      name: badge.name,
      comment: '',
      imageUrl: extractImageUrl(badge.image, 'thumbnail'),
      badge_level: badge?.badge_level ?? ''
    }
  }

  const getDefaultReco = (participantId: number) => {
    let defaultReco = ''
    if (participantsComments[participantId]) {
      defaultReco = participantsComments[participantId]
    } else if (globalComment) {
      defaultReco = globalComment
    }
    return defaultReco
  }

  // Init des valeurs des badges dans le formulaire
  useEffect(() => {
    if (activity?.badges && participations.length > 0) {
      // Liste de badges au format Formik Initial Values
      const activityBadges = activity.badges.map(badge => getBadgeFormProps(badge, participations[current].id))
      const participantBadges = participations[current].badges.map(badge => getBadgeFormProps(badge, participations[current].id))
      const mergedFormValues = [
        ...activityBadges.filter(badge => !participantBadges.find(participantBadge => participantBadge.id === badge.id)),
        ...participantBadges
      ]
      setFormValues({
        badges: mergedFormValues,
        comment: getDefaultReco(participations[current].id)
      })

      // Liste de badges au format ParticipationBadgesEditor
      const activityParticipationBadges = activity.badges.map(badge => getBadgeEditorProps(badge))
      const mergedEditorBadges = [
        ...activityParticipationBadges.filter(badge => !participantBadges.find(participantBadge => participantBadge.id === badge.id)),
        ...participations[current].badges
      ]
      setEditorBadges(mergedEditorBadges)
    }
  }, [participations, current])

  const appendBadges = (badges: AppendBadgePayload) => {
    const defaultReco = getDefaultReco(participations[current].id)
    setFormValues(currentState => ({
      ...currentState,
      badges: [
        ...currentState.badges,
        ...badges.map(badge => ({
          id: badge.id,
          checkbox: true,
          comment: defaultReco,
          badge_level: badge?.badge_level ?? ''
        }))
      ]
    }))
    setEditorBadges(currentState => ([
      ...currentState,
      ...badges.map(badge => ({
        id: badge.id,
        name: badge.name,
        comment: defaultReco,
        imageUrl: extractImageUrl(badge.image, 'thumbnail'),
        badge_level: badge?.badge_level ?? ''
      }))
    ]))
  }

  // Update handler
  const updateParticipation = async (values: ParticipationFormProps) => {
    if (activity && values.badges.length > 0) {
      const currentParticipationId = participations[current].id
      const badgesToUpdate = values.badges.filter(badge => badge.checkbox).map(badge => ({
        id: badge.id,
        comment: badge.comment,
        ...(badge?.badge_level && { badge_level: badge.badge_level })
      }))
      await apiParticipantBadgesUpdate(currentParticipationId, {
        status: 'present',
        selectedBadges: badgesToUpdate
      })
    }
  }

  const nextHandler = (values) => {
    setIsLoading(true)
    mutateActivity()
      .catch((error) => {
        Notifications.onError(error)
        throw error
      })
      .finally(() => {
        updateParticipation(values)
          .then(() => {
            setIsLoading(false)
            setCurrent(current + 1)
            window.scrollTo({ top: 0, behavior: 'smooth' })
          })
          .catch((error) => {
            Notifications.onError(error)
            throw error
          })
      })
  }

  const onSubmit = (values) => {
    setIsLoading(true)
    updateParticipation(values)
      .then(() => {
        setIsLoading(false)
        navigate(RoutesName.createActivityCompletion(id, RoutesName.ACTIVITY_COMPLETION_END))
      })
      .catch((error) => {
        Notifications.onError(error)
        throw error
      })
  }

  return (
    <div style={offset}>
      <CompletionHeader
        backLink={RoutesName.ACTIVITY_COMPLETION_PARTICIPANTS}
        currentStep={4}
        description='Attribuez un ou plusieurs badges à chaque participant. 👇'
        title='Attribution des badges'
        totalSteps={4}
      />

      <div className='max-w-container mx-auto -mt-n-offset px-6 md:px-0'>
        {participations.length > 0 &&
          <Formik
            enableReinitialize={true}
            initialValues={formValues}
            onSubmit={onSubmit}
          >
            {({ values }) => (
              <Form>
                {participations[current] &&
                  <ParticipationHead
                    defaultComment={getDefaultReco(participations[current].id)}
                    index={current + 1}
                    participation={participations[current]}
                    total={participations.length}
                  />
                }

                {activity &&
                  <ParticipationBadgesEditor
                    appendBadges={appendBadges}
                    id={participations[current].id}
                    participationBadges={editorBadges}
                  />
                }

                <div className='flex items-center gap-4 mt-14 sm:mt-24'>
                  {(current + 1 < participations.length) &&
                    <Button
                      isLoading={isLoading}
                      onClick={() => nextHandler(values)}
                      type='button'
                    >
                      Participant suivant
                    </Button>
                  }

                  {(current + 1 === participations.length) &&
                    <Button
                      isLoading={isLoading}
                      type='submit'
                    >
                      Étape suivante
                    </Button>
                  }
                </div>
              </Form>
            )}
          </Formik>
        }
      </div>
    </div>
  )
}

export default Badges
