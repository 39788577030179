import { AdminBadges } from '@interfaces/api/admin-badge'
import { axiosGet } from '@services/api/axios'
import { loadOnceSwrConfig } from '@services/api/swr-config'
import useSwr, { Fetcher } from 'swr'

export const useApiAdminBadges = () => {
  const { data, error, isValidating } = useSwr<AdminBadges>(
    '/external-app-plugin/admin-badges?_limit=-1',
    axiosGet as Fetcher<AdminBadges>,
    loadOnceSwrConfig
  )

  return {
    data,
    error,
    isValidating
  }
}
