import { UserInfo } from '@interfaces/api/user-info'
import { axiosGet } from '@services/api/axios'
import useSwr, { Fetcher } from 'swr'

export const useApiUserInfo = () => {
  const { data, error } = useSwr<UserInfo>(
    '/external-app-plugin/info',
    axiosGet as Fetcher<UserInfo>
  )

  return {
    data,
    isLoading: !data && !error,
    error
  }
}
