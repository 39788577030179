import { Participations } from '@interfaces/api/participation'
import { Filters } from '@interfaces/components/filters'
import ParticipantTableFilter from '@screens/participants//paticipant-table-filter'
import ParticipantTable from '@screens/participants/participant-table'
import { useApiParticipations } from '@services/api/participations/participations'
import Notifications from '@services/notifications'
import { useCallback, useEffect, useState } from 'react'


const ITEM_BY_PAGE = 10
const AVAILABLE_FILTERS = [Filters.All, Filters.Coming, Filters.Finished, Filters.InProgress]

const ParticipantSection = () => {
  const [page, setPage] = useState(1)
  const [sortColumn, setSortColumn] = useState('id')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')
  const [filter, setFilter] = useState<Filters>(Filters.All)

  const handleFilterClick = useCallback((filter: Filters) => {
    setPage(1)
    setFilter(filter)
  }, [])

  const onLabelClick = useCallback((name: string) => {
    if (name !== sortColumn) {
      setSortColumn(name)
      return
    }

    setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC')
  }, [sortColumn, sortDirection])


  const { data, error } = useApiParticipations({
    _start: (page - 1) * ITEM_BY_PAGE,
    _limit: ITEM_BY_PAGE,
    _sort: `${sortColumn}:${sortDirection}`,
    activityStatusFilter: filter
  })

  useEffect(() => {
    if (error) {
      Notifications.onError(error)
    }
  }, [error])

  const [participations, setParticipations] = useState<Participations | null>(null)

  useEffect(() => {
    if (data) {
      setParticipations(data)
    }
  }, [data])

  return <>
    <h2 className='text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate mb-3'>
      Mes participants
    </h2>
    <ParticipantTableFilter
      filters={AVAILABLE_FILTERS}
      onClick={handleFilterClick}
      selectedFilter={filter}
    />
    <ParticipantTable
      itemPerPage={ITEM_BY_PAGE}
      onChangePage={setPage}
      onLabelClick={onLabelClick}
      page={page}
      participations={participations}
    />
  </>
}

export default ParticipantSection
