import { stringifyError } from '@services/utils/error-utils'
import { toast, ToastOptions } from 'react-toastify'

const Notifications = {
  addErrorMessage: (message: string, options?: ToastOptions): void => {
    toast.error(message, options)
  },
  addInfoMessage: (message: string, options?: ToastOptions): void => {
    toast.info(message, options)
  },
  addSuccessMessage: (message: string, options?: ToastOptions): void => {
    toast.success(message, options)
  },
  addWarningMessage: (message: string, options?: ToastOptions): void => {
    toast.warn(message, options)
  },
  onError: (error): void => {
    Notifications.addErrorMessage(stringifyError(error))
  }
}

export default Notifications
