import { ButtonProps } from '@components/button/fancy-button.interfaces'
import { ReactComponent as Spinner } from '@components/icons/spinner.svg'
import { ArrowRightIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import React from 'react'

const Button = ({ className, children, icon, theme = 'primary', isLoading = false, ...props }: ButtonProps) => {
  const iconComponent = React.cloneElement(icon ?? <ArrowRightIcon />, {
    className: 'w-6 h-6'
  })

  const primaryStyle = {
    text: 'text-white',
    textBackground: 'bg-blue before:bg-white hover:before:opacity-10',
    icon: 'text-blue',
    iconBackground: 'bg-white'
  }
  const secondaryStyle = {
    text: 'text-white',
    textBackground: 'bg-white before:bg-blue-400 hover:before:opacity-40',
    icon: 'text-blue',
    iconBackground: 'bg-blue-400'
  }

  const buttonStyle = (theme === 'primary') ? primaryStyle : secondaryStyle

  const css = classNames(
    'relative overflow-hidden flex items-center gap-4 p-2 rounded-full',
    buttonStyle.text,
    'before:absolute before:z-0 before:w-96 before:h-64 before:rounded-full before:left-1/2 before:transition-all before:scale-15 before:-translate-x-48 before:opacity-0 hover:before:scale-100',
    (isLoading ? 'bg-mono-lightgray' : buttonStyle.textBackground),
    (isLoading && 'before:hidden'),
    className
  )

  return (
    <button
      {...props}
      className={css}
    >
      <div className={`relative w-14 h-14 flex items-center justify-center ${buttonStyle.iconBackground} rounded-full ${isLoading ? 'text-mono-lightgray' : buttonStyle.icon}`}>
        {isLoading ? <Spinner /> : iconComponent}
      </div>

      {children &&
        <div className='relative pr-8'>
          {isLoading && children ? 'Chargement' : children}
        </div>
      }
    </button>
  )
}

export default Button
