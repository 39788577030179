import { ZoneSelectItemsProps } from '@components/activity/activity-form/zone-select/zone-select-items.interfaces'
import SelectError from '@components/form/select-error'
import SelectLoading from '@components/form/select-loading'
import { Combobox } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/solid'
import { Zone } from '@interfaces/api/zone'
import { useApiZones } from '@services/api/zones'
import { stringifyError } from '@services/utils/error-utils'
import classNames from 'classnames'
import { useMemo } from 'react'

const ZoneSelectItems = ({ value, query }: ZoneSelectItemsProps) => {
  const { data, error } = useApiZones()

  const { nations, regions, departments } = useMemo(() => {
    const filteredData = data
      ? data.filter(({ name }) => name.toLowerCase().includes(query.toLocaleLowerCase()))
      : []

    return {
      nations: filteredData.filter(({ type }) => type === 'nation'),
      regions: filteredData.filter(({ type }) => type === 'region'),
      departments: filteredData.filter(({ type }) => type === 'department')
    }
  }, [query, data])

  if (error) {
    return (
      <SelectError message={stringifyError(error)} />
    )
  }

  if (!data) {
    return <SelectLoading />
  }

  const renderOption = (zone: Zone) => {
    return (
      <Combobox.Option
        key={zone.id}
        value={zone}
      >
        {({ active, selected }) => (
          <div className={classNames(
            'relative cursor-default select-none py-2 pl-3 pr-9',
            active ? 'bg-indigo-600 text-white' : 'text-gray-900'
          )}>
            <div className='flex items-center'>
              <div className='ml-4'>
                <div className='text-sm font-medium'>
                  {zone.type === 'department' && `${zone.dept_number ?? ''} - `}

                  {zone.name}
                </div>
              </div>
            </div>

            {(selected || value?.id === zone.id) && (
              <span
                className={classNames(
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                  active ? 'text-white' : 'text-indigo-600'
                )}
              >
                <CheckIcon aria-hidden='true' className='h-5 w-5 text-green-600' />
              </span>
            )}
          </div>
        )}
      </Combobox.Option>
    )
  }

  return (
    <div className=''>

      {nations.length > 0 && (
        <div>
          <h4 className='border-t border-b border-gray-400 bg-gray-200 px-6 py-1 text-sm font-medium text-gray-700'>Nation</h4>

          <div className='divide-y divide-gray-200'>
            {nations.map(zone => renderOption(zone))}
          </div>
        </div>
      )}

      {regions.length > 0 && (
        <div>
          <h4 className='border-t border-b border-gray-400 bg-gray-200 px-6 py-1 text-sm font-medium text-gray-700'>Régions</h4>

          <div className='divide-y divide-gray-200'>
            {regions.map(zone => renderOption(zone))}
          </div>
        </div>
      )}

      {departments.length > 0 && (
        <div>
          <h4 className='border-t border-b border-gray-400 bg-gray-200 px-6 py-1 text-sm font-medium text-gray-700'>Départements</h4>

          <div className='divide-y divide-gray-200'>
            {departments.map(zone => renderOption(zone))}
          </div>
        </div>
      )}
    </div>
  )
}

export default ZoneSelectItems
