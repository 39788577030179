import { ActivityFormHeaderProps } from '@components/activity/activity-form/activity-form-header.interfaces'
import ItemPublication from '@components/table/item-publication'
import { renderDateTime } from '@services/utils/date-utils'

const ActivityFormHeader = ({ activity }: ActivityFormHeaderProps) => {
  return (
    <div className='pb-5 border-b border-gray-200'>
      <div className='sm:flex sm:justify-between sm:items-baseline'>
        <div className='sm:w-0 sm:flex-1'>
          <h2 className='text-lg font-medium text-gray-900' id='message-heading'>
            {`ID: ${activity.id}`}
          </h2>

          <p className='mt-1 text-sm text-gray-500 truncate'>
            {`Crée le ${renderDateTime(activity.created_at)}`}
          </p>
        </div>

        <div className='mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start'>
          <ItemPublication published={!!activity.published_at} />
        </div>
      </div>
    </div>
  )
}

export default ActivityFormHeader
