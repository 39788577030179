import { ImageElementProps } from '@components/image/image-element/image-element.interfaces'
import { extractImageUrl } from '@services/utils/image-utils'
import { memo } from 'react'

const ImageElement = ({ image, className, placeholder, preferredSize }: ImageElementProps) => {
  const cls = className ?? ''

  if (image) {
    const src = extractImageUrl(image, preferredSize)
    if (src) {
      return <img alt='cover' className={cls} src={src} />
    }
  }

  if (placeholder) {
    return placeholder
  }

  return null
}

export default memo(ImageElement)
