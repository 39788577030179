import { FieldRowProps, Value } from '@components/form/field-row/field-row.interfaces'
import { ErrorMessage, Field } from 'formik'

const FieldRow = <T extends Value = string>({
  name,
  label,
  id,
  type,
  as,
  component,
  children,
  fieldStyles,
  labelStyles,
  placeholder
}: FieldRowProps<T>) => {
  let fieldClassName = fieldStyles ?? 'form-input mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md'
  const labelClassName = labelStyles ?? 'block text-sm font-medium text-gray-700'

  const props: {[k: string]: string} = {
    label
  }

  if (!fieldStyles) {
    if (as === 'textarea') {
      props.rows = '4'
      fieldClassName = 'form-textarea shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md'
    } else if (as === 'select') {
      fieldClassName = 'form-select shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md'
    }
  }


  return (
    <div className='flex flex-col'>
      {id && (
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
      )}

      <Field
        {...props}
        as={as}
        className={fieldClassName}
        component={component}
        id={id}
        name={name}
        placeholder={placeholder}
        type={type}
      >
        {children}
      </Field>

      <ErrorMessage
        className='text-red-600 mt-2 font-semibold'
        component='div'
        name={name}
      />
    </div>
  )
}

export default FieldRow
