export const stringifyError = (error): string => {
  if (error instanceof Error) {
    return error.message
  }

  if (error instanceof Object) {
    const httpRequestError = stringifyHttpRequestError(error)
    if (httpRequestError) {
      return httpRequestError
    }
  }

  // http error
  return typeof error
}

const hasProperty = (obj, property: string) => Object.prototype.hasOwnProperty.call(obj, property)

const flattenErrors = (errors: unknown | Record<string, unknown> | unknown[]): string[] => {
  let retVal: string[] = []
  if (Array.isArray(errors)) {
    errors.forEach((v) => {
      retVal = retVal.concat(flattenErrors(v))
    })
  } else if (errors instanceof Object) {
    Object.values(errors).forEach(v => {
      retVal = retVal.concat(flattenErrors(v))
    })
  } else {
    retVal.push(String(errors))
  }

  return retVal
}

export const stringifyHttpRequestError = (error): string | null => {
  if (hasProperty(error, 'data')) {
    const data = error.data
    if (hasProperty(data, 'error') && hasProperty(data, 'message')) {
      let message = `${String(data.error)}: ${String(data.message)}`

      if (hasProperty(data, 'data') && hasProperty(data.data, 'errors')) {
        message += '\n' + flattenErrors(data.data.errors).join('\n')
      }

      return message
    }

    return JSON.stringify(data)
  }

  if (hasProperty(error, 'statusText')) {
    return error.statusText
  }

  return null
}
