import create from 'zustand'
import { persist } from 'zustand/middleware'

interface ActivityCommentStore {
  comment: string
  setComment: (token: string) => void
}

export const useActivityCommentStore = create<ActivityCommentStore>(
  persist(
    (set) => ({
      comment: '',
      setComment: (comment) => set({ comment })
    }),
    {
      name: 'activity-comment-store', // unique name
      getStorage: () => localStorage
    }
  )
)
