import { Helpers } from '@interfaces/api/helper'
import { axiosGet } from '@services/api/axios'
import { loadOnceSwrConfig } from '@services/api/swr-config'
import useSwr, { Fetcher } from 'swr'

export const useApiHelpers = () => {
  const { data, error } = useSwr<Helpers>(
    '/external-app-plugin/helpers?_sort=name:asc&_limit=-1',
    axiosGet as Fetcher<Helpers>,
    loadOnceSwrConfig
  )

  return {
    data,
    error
  }
}
