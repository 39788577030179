import { ItemPublicationProps } from '@components/table/item-publication.interfaces'

const ItemPublication = ({ published }: ItemPublicationProps) => {
  if (published) {
    return (
      <span className='px-2 inline-flex text-xs leading-5 font-semibold bg-green-100 text-green-800 border border-green-500'>
        {'Publié'}
      </span>
    )
  }

  return (
    <span className='px-2 py-1 text-xs leading-5 font-semibold bg-blue-100 text-blue-800 border border-blue-500'>
      {'Brouillon'}
    </span>
  )
}

export default ItemPublication
