import { StrapiRole } from '@interfaces/api/role'
import { AdminUser } from '@interfaces/api/user'
import { FormikProps } from 'formik'
import * as Yup from 'yup'

export interface UserFormValues {
  firstname: string
  lastname: string
  email: string
  role: StrapiRole
}

export interface UserFormProps {
  user?: AdminUser
  bindFormikProps: (props: FormikProps<UserFormValues>) => void
  onFormSubmit: (form: UserFormValues) => Promise<AdminUser>
}


const requiredMsg = 'Une valeur est requise ici'

export const UserFormSchema = Yup.object({
  firstname: Yup.string().required(requiredMsg),
  lastname: Yup.string().required(requiredMsg),
  email: Yup.string().required(requiredMsg),
  role: Yup.mixed()
    .oneOf(Object.values(StrapiRole))
    .required(requiredMsg)
})
