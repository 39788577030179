import { ChildrenProps } from '@interfaces/components/children'

const Toast = ({ children }: ChildrenProps) => {
  return (
    <div className='w-full h-screen flex items-center justify-center px-6 before:absolute before:z-0 before:inset-0 before:h-1/2 before:bg-blue'>
      <div className='relative w-full max-w-md p-12 bg-white rounded-3xl text-mono-gray text-center'>
        {children}
      </div>
    </div>
  )
}

export default Toast
