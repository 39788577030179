import { ParticipationToggleProps } from '@components/form/participation-toggle'
import { useField } from 'formik'

const ParticipationToggle = ({ participation, index }: ParticipationToggleProps) => {
  const [field] = useField({
    name: `${index.toString()}.checkbox`,
    type: 'checkbox'
  })
  const { checked } = field
  const toggleStyles = checked ? 'bg-blue before:border-blue before:translate-x-full' : 'bg-mono-lightgray before:border-mono-lightgray'
  return (
    <label className='w-full flex flex-col md:flex-row items-center justify-between bg-white rounded-2xl md:p-6 text-left cursor-pointer'>
      <input className='hidden' type='checkbox' {...field} />

      {/* User Profile */}

      <div className='flex items-center gap-6 p-6 pb-3 md:p-0'>
        <img alt='Profile' className='shrink-0 w-14 h-14 rounded-full bg-blue overflow-hidden' src={participation.profile.picture?.url ?? '/assets/avatar-placeholder.png'} />

        <div>
          <div className='text-h4 leading-6 font-semibold text-mono-black'>
            {`${participation.profile.first_name} `}

            {participation.profile.last_name}
          </div>

          <div className='text-mono-lightgray'>{participation.user.email}</div>
        </div>
      </div>

      {/* Toggle */}

      <div className='flex items-center justify-between md:justify-center gap-4 p-6 md:p-0 border-t border-mono-separator md:border-none'>
        {checked
          ? <div className='font-bold text-mono-black'>Présent</div>
          : <div className='font-bold text-mono-lightgray'>Absent</div>
        }

        <div className={`
          ${toggleStyles}
          flex justify-start items-center w-16 h-8 rounded-full
          before:w-8 before:h-full before:bg-white before:rounded-full before:border-2
          transition-all before:transition-all
        `} />
      </div>
    </label>
  )
}

export default ParticipationToggle
