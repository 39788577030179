import PrimaryButton from '@components/button/primary-button'
import SecondaryButton from '@components/button/secondary-button'
import ImageLibraryContent from '@components/image/image-library-modal/image-library-content'
import { ImageLibraryModalProps } from '@components/image/image-library-modal/image-library-modal.interfaces'
import Modal from '@components/modal'
import { Dialog } from '@headlessui/react'
import { Image } from '@interfaces/api/image'
import { memo, useCallback, useRef, useState } from 'react'

const ImageLibraryModal = ({ show, onRequestClose, onSelect }: ImageLibraryModalProps) => {
  const [imageSelected, setImageSelected] = useState<Image | null>(null)
  const closeButtonRef = useRef(null)

  const onImageSelect = useCallback((image: Image) => {
    setImageSelected(image)
  }, [])

  const onSubmit = useCallback(() => {
    if (imageSelected) {
      onSelect(imageSelected)
    }
  }, [imageSelected, onSelect])

  return (
    <Modal initialFocus={closeButtonRef} onRequestClose={onRequestClose} show={show}>
      <div className='inline-flex flex-col w-full max-w-4xl max-h-128 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md'>

        <div className='text-center p-3 bg-indigo-600'>
          <Dialog.Title
            as='h3'
            className='text-lg leading-6 font-medium text-white'
          >
            {'Bibliothèque'}
          </Dialog.Title>
        </div>

        <div className='grow overflow-y-auto p-2'>
          <ImageLibraryContent onSelect={onImageSelect} onSubmit={onSubmit} />
        </div>

        <div className='bg-gray-200 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
          <PrimaryButton
            className='mt-3 ml-3 w-auto text-sm'
            onClick={onSubmit}
            type='button'
          >
            {'Sélectionner'}
          </PrimaryButton>

          <SecondaryButton
            className='mt-3 ml-3 w-auto text-sm'
            onClick={onRequestClose}
            ref={closeButtonRef}
            type='button'
          >
            {'Fermer'}
          </SecondaryButton>
        </div>
      </div>
    </Modal>
  )
}

export default memo(ImageLibraryModal)
