import { FullPageLoadingProps } from '@components/loading/full-page-loading.interfaces'
import Spinner from '@components/loading/spinner'

const FullPageLoading = ({ text }: FullPageLoadingProps) => {
  return (
    <div className='flex flex-col justify-center items-center h-screen'>
      <div className='h-24 w-auto'>
        <Spinner className='h-full w-auto' />
      </div>

      <div className='mt-4'>{text}</div>
    </div>
  )
}

export default FullPageLoading
