import FieldRow from '@components/form/field-row/field-row'
import { UserFormProps, UserFormSchema, UserFormValues } from '@components/user/user-form/user-form.interfaces'
import { STRAPI_ROLE_NAMES, StrapiRole } from '@interfaces/api/role'
import { Form, Formik, FormikConfig } from 'formik'


const UserForm = ({ bindFormikProps, onFormSubmit, user }: UserFormProps) => {
  let initialValues: UserFormValues = {
    firstname: '',
    lastname: '',
    email: '',
    role: '' as StrapiRole
  }

  if (user) {
    initialValues = {
      firstname: user?.firstname ?? '',
      lastname: user?.lastname ?? '',
      email: user?.email ?? '',
      role: (user?.roles?.[0]?.code ?? '')
    }
  }

  const onSubmit: FormikConfig<UserFormValues>['onSubmit'] = (values, { setSubmitting }) => {
    onFormSubmit(values)
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <div className='overflow-x-auto'>
      <div className='py-2 align-middle inline-block min-w-full '>
        <div className='shadow border-b border-gray-200 rounded-lg bg-white p-8'>
          <div className='mt-3'>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={UserFormSchema}
            >
              {props => {
                bindFormikProps(props)

                return (
                  <Form>
                    <div className='grid grid-cols-2 gap-x-4 gap-y-4'>

                      <FieldRow
                        id='firstname'
                        label='Prénom'
                        name='firstname'
                      />

                      <FieldRow
                        id='lastname'
                        label='Nom'
                        name='lastname'
                      />

                      <FieldRow
                        id='email'
                        label='Email'
                        name='email'
                      />

                      <FieldRow
                        as='select'
                        id='role'
                        label='Rôle'
                        name='role'
                      >
                        <option value=''>Sélectionnez un rôle</option>

                        <option value={StrapiRole.AUTHOR_CODE}>{STRAPI_ROLE_NAMES[StrapiRole.AUTHOR_CODE]}</option>
                      </FieldRow>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserForm
