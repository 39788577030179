import axios, { AxiosRequestConfig, ResponseType } from 'axios'

export const setApiURL = (url?: string) => {
  if (url) {
    localStorage.setItem('openbadge_admin_api_url', url)
  } else {
    localStorage.removeItem('openbadge_admin_api_url')
  }
}

export const setAuthorizationBearer = (token?: string) => {
  if (token) {
    localStorage.setItem('openbadge_admin_jwt', token)
  } else {
    localStorage.removeItem('openbadge_admin_jwt')
  }
}

const axiosRequest = async <DATA>({
  data,
  method = 'POST',
  params,
  timeout = 10000,
  url,
  headers = {},
  responseType
}: AxiosRequestConfig): Promise<DATA> => {
  const baseURL = localStorage.getItem('openbadge_admin_api_url') ?? ''
  const authorizationBearer = localStorage.getItem('openbadge_admin_jwt')

  if (authorizationBearer) {
    headers.authorization = `Bearer ${authorizationBearer}`
  }

  return await new Promise((resolve, reject) => {
    axios({
      baseURL,
      data,
      headers,
      method,
      params,
      timeout,
      url,
      responseType
    }).then(
      ({ data }) => {
        resolve(typeof data === 'string' ? JSON.parse(data) : data)
      },
      (payload) => {
        if (payload.response) {
          const { response } = payload

          reject(response)
        } else {
          reject(payload)
        }
      }
    )
  })
}

export const axiosDelete = async <RESPONSE_DATA>(
  url: string,
  timeout?: number
) =>
  await axiosRequest<RESPONSE_DATA>({
    method: 'DELETE',
    timeout,
    url
  })

export const axiosPut = async <BODY, RESPONSE_DATA>(
  url: string,
  body: BODY,
  timeout?: number
) =>
  await axiosRequest<RESPONSE_DATA>({
    data: body,
    method: 'PUT',
    timeout,
    url
  })

export const axiosPatch = async <BODY, RESPONSE_DATA>(
  url: string,
  body: BODY,
  timeout?: number
) =>
  await axiosRequest<RESPONSE_DATA>({
    data: body,
    method: 'PATCH',
    timeout,
    url
  })

export const axiosPost = async <BODY, RESPONSE_DATA>(
  url: string,
  body: BODY,
  timeout?: number
) =>
  await axiosRequest<RESPONSE_DATA>({
    data: body,
    method: 'POST',
    timeout,
    url
  })

export const axiosGet = async <RESPONSE_DATA>(
  url: string,
  params?: Record<string, string | number>,
  timeout?: number,
  responseType?: ResponseType
) =>
  await axiosRequest<RESPONSE_DATA>({
    data: params,
    method: 'GET',
    timeout,
    url,
    responseType
  })

export const axiosPostFilesData = async <RESPONSE_DATA>(
  url: string,
  data: FormData,
  timeout?: number
) => await axiosRequest<RESPONSE_DATA>({
  data,
  method: 'POST',
  timeout,
  url,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})
