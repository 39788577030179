import { BadgeToggleProps } from '@components/form/badge-toggle/badge-toggle.interfaces'
import { useField } from 'formik'

const UserToggle = ({ badgeId }: BadgeToggleProps) => {
  const [field] = useField({
    name: badgeId,
    type: 'checkbox'
  })
  const { checked } = field
  const toggleStyles = checked ? 'bg-blue before:border-blue before:translate-x-full' : 'bg-mono-lightgray before:border-mono-lightgray'
  const toggleLabelStyles = checked ? 'text-blue' : 'text-mono-gray'

  return (
    <label
      className = 'flex items-center justify-between gap-4 p-6 border-t border-mono-separator cursor-pointer'
    >
      <input className='hidden' type='checkbox' {...field} />

      <div className={`font-bold ${toggleLabelStyles}`}>Attribuer le badge</div>

      <div className={`
        ${toggleStyles}
        flex justify-start items-center w-16 h-8 rounded-full
        before:w-8 before:h-full before:bg-white before:rounded-full before:border-2
        transition-all before:transition-all
      `} />
    </label >
  )
}

export default UserToggle
