import PageTitle from '@components/layout/page-title/page-title'
import { RoutesName } from '@navigation/routes-name'
import ActivityGrid from '@screens/activities-type/activity-grid'


const ActivitiesType = () => {
  return (
    <div className='p-4'>

      <PageTitle backRoute={RoutesName.ACTIVITIES} title='Activités type' />

      <div className='pt-4'>
        <p className='mb-4'>
          Les activités types sont des templates que vous pouvez utiliser afin de créer rapidement des activités.
          <br />
          Cliquez sur une activité type pour l'utiliser comme base.
        </p>

        <ActivityGrid />
      </div>
    </div>
  )
}

export default ActivitiesType
