import { useApiUserInfo } from '@services/api/user-info'
import { useUserInfoStore } from '@services/stores/user-info'
import { useEffect } from 'react'

export const useLoadData = () => {
  const { setUserInfo } = useUserInfoStore()
  const { data, error, isLoading } = useApiUserInfo()

  useEffect(() => {
    if (data) {
      setUserInfo(data)
    }
  }, [data])

  return { error, isLoading }
}
