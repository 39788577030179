import ImageElement from '@components/image/image-element'
import ImagePlaceholder from '@components/image/image-placeholder'
import ItemPublication from '@components/table/item-publication'
import { EyeIcon } from '@heroicons/react/solid'
import { BadgeTableRowProps } from '@screens/badges/badge-table-row.interfaces'
import { memo } from 'react'

const BadgeTableRow = ({ badge, onOpenDetail }: BadgeTableRowProps) => {
  return (
    <tr key={badge.id}>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {badge.id}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='flex items-center'>
          <div className='flex-shrink-0 w-12 h-12 flex items-center justify-center bg-black'>
            <ImageElement
              className='max-h-12 w-auto'
              image={badge?.image}
              placeholder={<ImagePlaceholder className='w-12 h-12' />}
              preferredSize={'thumbnail'}
            />
          </div>

          <div className='ml-3'>
            <div className='font-medium'>
              {badge?.name}
            </div>
          </div>
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {badge?.theme?.name ?? 'N/A'}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {badge?.type}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <ItemPublication published={!!badge.published_at} />
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-center'>
        <button
          className=' flex items-center py-0.5 text-indigo-600 hover:text-indigo-900'
          onClick={() => onOpenDetail(badge)}
          title='Voir les détails'
        >
          <EyeIcon className='h-5 mr-2 w-auto' />

          <span>Détails</span>
        </button>
      </td>
    </tr>
  )
}

export default memo(BadgeTableRow)
