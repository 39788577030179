import { CompletionHeaderProps } from '@components/completion-header/completion-header.interfaces'
import { ReactComponent as ArrowRight } from '@components/icons/arrow-right.svg'
import ProgressionSteps from '@components/progression-steps/progression-steps'
import { RoutesName } from '@navigation/routes-name'
import { Link, useParams } from 'react-router-dom'

const CompletionHeader = ({ backLink, title, description, currentStep, totalSteps }: CompletionHeaderProps) => {
  const { id } = useParams()
  let backLinkWithId = ''
  if (id && backLink) {
    backLinkWithId = RoutesName.createActivityCompletion(id, backLink)
  }
  return (
    <div className='flex flex-col items-center justify-center bg-blue pt-10 sm:pt-24 pb-12 px-6 text-white after:h-n-offset'>
      <div className='w-full max-w-container'>
        {backLink && (
          <Link className='flex items-center gap-2 mb-6' to={backLinkWithId}>
            <ArrowRight className='text-red' />
            Retour
          </Link>
        )}

        <div className='text-header font-semibold'>{title}</div>

        <div className='mt-2 font-medium'>{description}</div>

        <ProgressionSteps current={currentStep} total={totalSteps} />
      </div>
    </div>
  )
}

export default CompletionHeader
