import { StrapiRole } from '@interfaces/api/role'
import { AdminUser, User } from '@interfaces/api/user'
import { axiosPost, axiosPut } from '@services/api/axios'

export interface CreateUserPayload {
  email: string
}

export interface UserSavePayload {
  firstname: string | null
  lastname: string | null
  email: string | null
  role: StrapiRole | null
}

export const apiCreateUser = async (payload: CreateUserPayload) =>
  await axiosPost<CreateUserPayload, User>('/external-app-plugin/users', payload)

export const apiCreateAdminUser = async (payload: UserSavePayload) =>
  await axiosPost<UserSavePayload, AdminUser>('/external-app-plugin/users/admin', payload)

export const apiEditAdminUser = async (userId: number, payload: UserSavePayload) =>
  await axiosPut<UserSavePayload, AdminUser>(`/external-app-plugin/users/admin/${userId}`, payload)
