import { Participations } from '@interfaces/api/participation'
import { axiosGet } from '@services/api/axios'
import { loadOnceSwrConfig } from '@services/api/swr-config'
import qs from 'qs'
import useSwr, { Fetcher } from 'swr'

type Props = Record<string, unknown>

export const useApiParticipations = (params: Props, preventFetch?: boolean) => {
  const queryString = qs.stringify(params)

  const { data, error } = useSwr<Participations>(preventFetch
    ? null
    : `/external-app-plugin/participations?${queryString}`,
  axiosGet as Fetcher<Participations>,
  loadOnceSwrConfig
  )

  return {
    data,
    error
  }
}
