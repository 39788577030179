export const RoutesName = {
  HOME: '/',
  ACTIVITIES: '/activities',
  ACTIVITIES_TYPE: '/activities/type',
  ACTIVITY_CREATE: '/activities/create',
  ACTIVITY_EDIT: '/activities/:id/edit',
  createActivityEdit: (id: number) => `/activities/${id}/edit`,
  ACTIVITY_COMPLETION: '/activity-completion/:id',
  ACTIVITY_COMPLETION_INFORMATIONS: '/activity-completion/:id/informations',
  ACTIVITY_COMPLETION_ACTIVITY: '/activity-completion/:id/activity',
  ACTIVITY_COMPLETION_PARTICIPANTS: '/activity-completion/:id/participants',
  ACTIVITY_COMPLETION_BADGES: '/activity-completion/:id/badges',
  ACTIVITY_COMPLETION_END: '/activity-completion/:id/end',
  ACTIVITY_COMPLETION_COMPLETED: '/activity-completion/:id/already-completed',
  createActivityCompletion: (id: string | number, path: string) => path.replace(':id', id.toString()),
  BADGES: '/badges',
  PARTICIPANTS: '/participants',
  USERS: '/users',
  USER_CREATE: '/users/create',
  USER_EDIT: '/users/:id/edit'
}
