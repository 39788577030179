import { Badge } from '@interfaces/api/badge'
import { Helper } from '@interfaces/api/helper'
import { Image } from '@interfaces/api/image'
import { Theme } from '@interfaces/api/theme'
import { Zone } from '@interfaces/api/zone'
import * as Yup from 'yup'

export interface ActivityFormParticipantPayload {
  email?: string | null
  firstName?: string | null
  lastName?: string | null
  picture?: Image | null
  userId: number
}

export interface ActivityFormValues {
  title: string
  start_at: Date | ''
  duration: number | ''
  address: string
  prerequisite: string
  about: string
  status: 'open' | 'finished'
  visibility: 'public' | 'private'
  participants_limit: number | ''
  theme: Theme | null
  helper: Helper | null
  zone: Zone | null
  cover: Image | null
  badges: Badge[]
  participantProfiles: ActivityFormParticipantPayload[]
  duplicable: boolean | null
}

const requiredMsg = 'Une valeur est requise ici'
const minValueMsg = 'La valeur doit etre supérieur ou égal à 1'

export const ActivityFormSchema = Yup.object({
  title: Yup.string().required(requiredMsg),
  start_at: Yup.string().required(requiredMsg),
  duration: Yup.number()
    .min(1, minValueMsg)
    .required(requiredMsg),
  address: Yup.string().required(requiredMsg),
  prerequisite: Yup.string().required(requiredMsg),
  about: Yup.string().required(requiredMsg),
  status: Yup.mixed()
    .oneOf(['open', 'finished'])
    .required(requiredMsg),
  visibility: Yup.mixed()
    .oneOf(['public', 'private'])
    .required(requiredMsg),
  participants_limit: Yup.number()
    .min(1, minValueMsg)
    .required(requiredMsg),
  theme: Yup.object()
    .nullable()
    .required(requiredMsg),
  helper: Yup.object()
    .nullable()
    .required(requiredMsg),
  zone: Yup.object()
    .nullable()
    .required(requiredMsg),
  cover: Yup.object()
    .nullable()
    .required(requiredMsg),
  badges: Yup.array(Yup.object())
    .nullable()
    .min(1, 'Vous devez choisir au moins un badge')
    .required(requiredMsg),
  participantProfiles: Yup.array(Yup.object())
    .nullable()
    .required(requiredMsg)
})

export const ActivityFormSchemaAuthor = ActivityFormSchema.omit(['helper'])
