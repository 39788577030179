import { AdminUser, AdminUsers } from '@interfaces/api/user'
import { axiosGet } from '@services/api/axios'
import { loadOnceSwrConfig } from '@services/api/swr-config'
import qs from 'qs'
import useSwr, { Fetcher } from 'swr'

type Props = Record<string, unknown>

export const useApiAdminUsers = (params: Props) => {
  const queryString = qs.stringify(params)

  const { data, error } = useSwr<AdminUsers>(
    `/external-app-plugin/users/admin?${queryString}`,
    axiosGet as Fetcher<AdminUsers>,
    loadOnceSwrConfig
  )

  return {
    data,
    error
  }
}

export const useApiAdminUser = (id: number) => {
  const { data, error, mutate, isValidating } = useSwr<AdminUser>(
    `/external-app-plugin/users/admin/${id}`,
    axiosGet as Fetcher<AdminUser>,
    loadOnceSwrConfig
  )

  return {
    data,
    error,
    isValidating,
    mutate
  }
}
