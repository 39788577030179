import ActivityForm from '@components/activity/activity-form'
import { ActivityFormValues } from '@components/activity/activity-form/activity-form-interfaces'
import { convertActivityFormValuesToActivitySavePayload } from '@components/activity/activity-form/activity-form-utils'
import PrimaryButton from '@components/button/primary-button'
import PageTitle from '@components/layout/page-title/page-title'
import { SaveIcon } from '@heroicons/react/solid'
import { Activity } from '@interfaces/api/activity'
import { ActivityDetailed } from '@interfaces/api/activity-detailed'
import { RoutesName } from '@navigation/routes-name'
import { apiCreateActivity } from '@services/api/activities/activity-save'
import Notifications from '@services/notifications'
import { FormikProps } from 'formik/dist/types'
import { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface LocationState {
  duplicate: ActivityDetailed
  previousRoute?: string
}

const ActivityCreate = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()
  const state = useLocation()?.state as LocationState
  const duplication = state?.duplicate
  const isDuplicate = !!duplication

  // Bound formik props from children
  let formikProps: FormikProps<ActivityFormValues> | null = null
  const bindFormikProps = (props: FormikProps<ActivityFormValues>) => {
    formikProps = props
  }

  // On Save button, we submit form
  const onSaveButtonClick = async () => {
    if (formikProps) {
      const errors = await formikProps.validateForm()
      if (Object.keys(errors).length > 0) {
        Notifications.addWarningMessage('Le formulaire est invalide')
      }

      await formikProps.submitForm()
    }
  }

  // On form submit, save the activity
  const onFormSubmit = useCallback(async (values: ActivityFormValues): Promise<Activity> => {
    setIsSubmitting(true)
    return await apiCreateActivity(convertActivityFormValuesToActivitySavePayload(values))
      .then(activity => {
        Notifications.addSuccessMessage('Nouvelle activité créee')
        setTimeout(() => navigate(RoutesName.createActivityEdit(activity.id)), 50)

        return activity
      })
      .catch(err => {
        setIsSubmitting(false)
        Notifications.onError(err)
        throw err
      })
  }, [])

  const buttons = <>
    <PrimaryButton
      className='w-auto text-md'
      disabled={isSubmitting}
      isLoading={isSubmitting}
      onClick={onSaveButtonClick}
    >
      <SaveIcon aria-hidden='true' className='-ml-0.5 mr-2 h-4 w-auto' />

      {state?.previousRoute && state?.previousRoute === RoutesName.ACTIVITIES_TYPE
        ? 'Créer une activité en utilisant ce template'
        : 'Enregistrer'
      }
    </PrimaryButton>
  </>

  return (
    <div className='p-4'>
      <PageTitle
        backRoute={RoutesName.ACTIVITIES}
        buttons={buttons}
        title={'Créer une activité'}
      />

      <div className='p-4 pb-10'>
        <ActivityForm activity={duplication} bindFormikProps={bindFormikProps} isDuplicate={isDuplicate} onFormSubmit={onFormSubmit} />
      </div>
    </div>
  )
}

export default ActivityCreate
