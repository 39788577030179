import BadgeEntry from '@components/form/badge-collection/badge'
import { BadgeCollectionProps } from '@components/form/badge-collection/badge-collection.interfaces'
import { FieldArray } from 'formik'

const BadgeCollection = ({ name, list }: BadgeCollectionProps) => {
  return (
    <FieldArray
      name={name}
      render={() => (
        <div className='flex flex-col gap-4'>
          {list.length > 0 && list.map((badge, index) => (
            <BadgeEntry
              badge={badge}
              index={index}
              key={index}
            />
          ))}
        </div>
      )}
    />
  )
}

export default BadgeCollection
