import { Themes } from '@interfaces/api/theme'
import { axiosGet } from '@services/api/axios'
import { loadOnceSwrConfig } from '@services/api/swr-config'
import useSwr, { Fetcher } from 'swr'

export const useApiThemes = () => {
  const { data, error } = useSwr<Themes>(
    '/external-app-plugin/themes?_sort=name:asc&_limit=-1',
    axiosGet as Fetcher<Themes>,
    loadOnceSwrConfig
  )

  return {
    data,
    error
  }
}
