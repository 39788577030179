import { ImageUploadCurrentProps } from '@components/image/image-upload-modal/image-upload-current.interfaces'

const ImageUploadCurrent = ({ file }: ImageUploadCurrentProps) => {
  return (
    <div className='flex justify-center items-center max-w-lg h-48' >
      <img alt={file.name} className='max-w-full max-h-full' src={URL.createObjectURL(file)} />
    </div>
  )
}

export default ImageUploadCurrent
