import { Activity } from '@interfaces/api/activity'
import { axiosPost, axiosPut } from '@services/api/axios'

export interface ActivitySavePayload {
  title: string | null
  start_at: Date | null
  duration: number | null
  address: string | null
  prerequisite: string | null
  about: string | null
  status: 'open' | 'finished'
  visibility: 'public' | 'private'
  participants_limit: number | null
  theme: number | null
  helper: number | null
  zone: number | null
  cover: number | null
  badges: number[] | null
  duplicable: boolean | null
}

export const apiCreateActivity = async (payload: ActivitySavePayload) =>
  await axiosPost<ActivitySavePayload, Activity>('/external-app-plugin/activities/', payload)

export const apiEditActivity = async (id: number, payload: Partial<ActivitySavePayload>) =>
  await axiosPut<Partial<ActivitySavePayload>, Activity>(`/external-app-plugin/activities/${id}`, payload)
