import PageTitle from '@components/layout/page-title/page-title'
import Menu from '@components/menu'
import OtherParticipantSection from '@screens/participants/other-participant-section'
import ParticipantSection from '@screens/participants/participant-section'
import { useUserInfoStore } from '@services/stores/user-info'


const Participants = () => {
  const { userInfo } = useUserInfoStore()

  return (
    <div className='p-4'>
      <Menu />

      <PageTitle title={'Participants'} />

      <div className='pt-4'>
        <ParticipantSection />
      </div>

      {!userInfo?.isAuthor && (
        <div className='mt-10'>
          <OtherParticipantSection />
        </div>
      )}
    </div>
  )
}

export default Participants
