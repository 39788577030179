import { ImageUploadDropzoneProps } from '@components/image/image-upload-modal/image-upload-dropzone.interfaces'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

const ImageUploadDropzone = ({ onDropFile }: ImageUploadDropzoneProps) => {
  const onDrop = useCallback(acceptedFiles => {
    onDropFile(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg,image/png,image/gif,image/webp',
    maxFiles: 1,
    onDrop
  })

  return (
    <div
      className='max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <div className='space-y-1 text-center'>
        <svg
          aria-hidden='true'
          className='mx-auto h-12 w-12 text-gray-400'
          fill='none'
          stroke='currentColor'
          viewBox='0 0 48 48'
        >
          <path
            d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
          />
        </svg>

        <div className='flex text-sm text-gray-600'>
          <p>
            {isDragActive
              ? 'Déposez le fichier ici ...'
              : 'Glissez-déposez un fichier ici, ou cliquez pour sélectionner un fichier'}
          </p>

        </div>

        <p className='text-xs text-gray-500'>PNG, JPG, GIF, WEBP jusqu'à 10MB</p>
      </div>
    </div>
  )
}

export default ImageUploadDropzone
