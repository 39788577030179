import Toast from '@components/toast/toast'

const AlreadyCompleted = () => {
  return (
    <Toast>
      <h2 className='text-h2 text-mono-black font-semibold'>Merci !</h2>

      <p className='mt-2'>Vous avez déjà attribué des badges.</p>

      <p className='mt-6'>Merci de vous rapprocher de l'administrateur si vous avez un soucis concernant la complétion de cette activité.</p>
    </Toast>
  )
}

export default AlreadyCompleted
