import TableFooter from '@components/table/table-footer'
import { AdminUsers } from '@interfaces/api/user'
import UserTableHead from '@screens/users/user-table-head'
import UserTableLoading from '@screens/users/user-table-loading'
import UserTableRow from '@screens/users/user-table-row'
import { useApiAdminUsers } from '@services/api/user/admin'
import Notifications from '@services/notifications'
import { useCallback, useEffect, useState } from 'react'


const ITEM_BY_PAGE = 10

const UserTable = () => {
  // const { userInfo } = useUserInfoStore()

  // Activities loading parameters
  const [page, setPage] = useState(1)
  const [sortColumn, setSortColumn] = useState('id')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')

  const onLabelClick = useCallback((name: string) => {
    if (name !== sortColumn) {
      setSortColumn(name)
      return
    }

    setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC')
  }, [sortColumn, sortDirection])

  // Loading users
  const { data, error } = useApiAdminUsers({
    _start: (page - 1) * ITEM_BY_PAGE,
    _limit: ITEM_BY_PAGE,
    _sort: `${sortColumn}:${sortDirection}`
  })

  useEffect(() => {
    if (error) {
      Notifications.onError(error)
    }
  }, [error])

  // Store users, so we can display something during page or sort change
  const [users, setUsers] = useState<AdminUsers | null>(null)

  useEffect(() => {
    if (data) {
      setUsers(data)
    }
  }, [data])


  return (
    <>
      <div className='overflow-x-auto'>
        <div className='inline-block min-w-full py-2'>
          <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg'>
            <table className='min-w-full divide-y divide-gray-300'>
              <UserTableHead onLabelClick={onLabelClick} />

              <tbody className='bg-white divide-y divide-gray-200'>
                {
                  !data && users && (
                    <UserTableLoading smaller />
                  )
                }

                {
                  users
                    ? users.results.map((user) => (
                      <UserTableRow
                        key={user.id}
                        user={user}
                      />
                    ))
                    : <UserTableLoading />
                }
              </tbody>
            </table>

            <TableFooter
              gotoPage={setPage}
              itemPerPage={ITEM_BY_PAGE}
              meta={users?.meta ?? null}
              page={page}
            />
          </div>
        </div>
      </div>

      {/* <ActivityDeleteModal
        activity={activityToDelete}
        onDeleteDone={onDeleteModalDeleteDone}
        onRequestClose={onDeleteModalRequestClose}
      /> */}
    </>
  )
}

export default UserTable
