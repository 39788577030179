import PageTitle from '@components/layout/page-title/page-title'
import Menu from '@components/menu'
import { PlusCircleIcon } from '@heroicons/react/solid'
import { RoutesName } from '@navigation/routes-name'
import UserTable from '@screens/users/user-table'
import { Link } from 'react-router-dom'


const Users = () => {
  const buttons = (
    <>
      <Link
        className='inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        to={RoutesName.USER_CREATE}
      >
        <PlusCircleIcon aria-hidden='true' className='-ml-0.5 mr-2 h-4 w-4' />

        {'Créer un utilisateur'}
      </Link>
    </>
  )

  return (
    <div className='p-4'>
      <Menu />

      <PageTitle buttons={buttons} title={'Utilisateurs'} />

      <div className='pt-4'>
        <UserTable />
      </div>
    </div>
  )
}

export default Users
