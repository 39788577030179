import { useEffect, useState } from 'react'

/**
 * "useDebounce returns a debounced version of the value passed in as an argument, with the delay in
 * milliseconds also passed in as an argument."
 *
 * The first argument is the value we want to debounce. The second argument is the delay in
 * milliseconds
 * @param {T} value - The value to be debounced.
 * @param {number} delay - The number of milliseconds to wait before updating the state.
 * @returns A debounced value.
 */
export default function useDebounce<T> (value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      return () => {
        clearTimeout(handler)
      }
    },
    [value]
  )

  return debouncedValue
}
