import ActivityStatus from '@components/activity/activity-status'
import ImageElement from '@components/image/image-element'
import ImagePlaceholder from '@components/image/image-placeholder'
import ItemPublication from '@components/table/item-publication'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/solid'
import { RoutesName } from '@navigation/routes-name'
import { ActivityTableRowProps } from '@screens/activities/activity-table-row.interfaces'
import { renderDate, renderTime, renderTimeTable } from '@services/utils/date-utils'
import { getZoneTypeLabel } from '@services/utils/zone-utils'
import { memo } from 'react'
import { Link } from 'react-router-dom'

const ActivityTableRow = ({ activity, showHelper, onRequestDelete }: ActivityTableRowProps) => {
  return (
    <tr key={activity.id}>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {activity.id}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='flex items-center'>
          <div className='flex-shrink-0 w-12 h-12 flex items-center justify-center bg-black'>
            <ImageElement
              className='max-h-12 w-auto'
              image={activity.cover}
              placeholder={<ImagePlaceholder className='w-12 h-12' />}
              preferredSize={'thumbnail'}
            />
          </div>

          <div className='ml-3'>
            <div className='font-medium'>
              {activity.title}
            </div>

            <div className='text-gray-500'>
              <span className='font-bold'>{'Thème: '}</span>

              {activity.theme?.name}
            </div>
          </div>
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {activity.start_at ? renderDate(activity.start_at) : '-'}
        </div>

        <div className='text-gray-500'>
          {activity.start_at
            ? (
              activity.duration
                ? renderTimeTable(activity.start_at, activity.duration)
                : renderTime(activity.start_at)
            )
            : '-'}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {activity.zone?.name}
        </div>

        <div className='text-sm text-gray-500'>
          {activity.zone && getZoneTypeLabel(activity.zone.type)}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <div className='font-medium'>
          {`${activity.participants_number ?? '?'} sur ${activity.participants_limit ?? '?'}`}
        </div>
      </td>

      {showHelper && (
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
          <div className='font-medium'>
            {activity.helper?.name}
          </div>
        </td>
      )}

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <ActivityStatus status={activity.status} />
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
        <ItemPublication published={!!activity.published_at} />
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-center'>
        <div className='flex'>
          <Link
            className='py-0.5 text-indigo-600 hover:text-indigo-900'
            title={'Éditer'}
            to={RoutesName.createActivityEdit(activity.id)}
          >
            <PencilAltIcon className='h-5 w-auto' />

            <span className='sr-only'>{'Éditer'}</span>
          </Link>

          {!activity.published_at && (
            <button
              className='py-0.5 text-red-600 hover:text-red-900'
              onClick={() => onRequestDelete(activity)}
              title='Supprimer'
            >
              <TrashIcon className='h-5 w-auto' />

              <span className='sr-only'>{'Supprimer'}</span>
            </button>
          )}
        </div>
      </td>
    </tr>
  )
}

export default memo(ActivityTableRow)
