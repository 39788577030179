import { RoutesName } from '@navigation/routes-name'
import { Link, useLocation } from 'react-router-dom'

const links = {
  [RoutesName.ACTIVITIES]: 'Activités',
  [RoutesName.BADGES]: 'Badges',
  [RoutesName.PARTICIPANTS]: 'Participants',
  [RoutesName.USERS]: 'Utilisateurs'
}

const Menu = () => {
  const location = useLocation()

  return <ul className='flex items-center space-x-5 mb-5'>
    {Object.entries(links).map(([url, name], index) => (
      <li key={`link-${index}`}>
        <Link className={location.pathname === url ? 'text-indigo-600' : ''} to={url}>
          {name}
        </Link>
      </li>
    ))}
  </ul>
}

export default Menu
