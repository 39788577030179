import { ActivityDetailed } from '@interfaces/api/activity-detailed'
import { axiosGet } from '@services/api/axios'
import { loadOnceSwrConfig } from '@services/api/swr-config'
import useSwr, { Fetcher } from 'swr'

export const useApiActivityDetailed = (id: number) => {
  const { data, error, isValidating, mutate } = useSwr<ActivityDetailed>(
    `/activity-plugin/activities/${id}`,
    axiosGet as Fetcher<ActivityDetailed>,
    loadOnceSwrConfig
  )

  return {
    data,
    error,
    isValidating,
    mutate
  }
}

export const useExternalAppApiActivityDetailed = (id: number) => {
  const { data, error, isValidating, mutate } = useSwr<ActivityDetailed>(
    `/external-app-plugin/activities/${id}`,
    axiosGet as Fetcher<ActivityDetailed>,
    loadOnceSwrConfig
  )

  return {
    data,
    error,
    isValidating,
    mutate
  }
}
