import BadgeFinder from '@components/badge-finder/badge-finder'
import Button from '@components/button/fancy-button'
import BadgeCollection from '@components/form/badge-collection'
import FullPageLoading from '@components/loading/full-page-loading'
import { ParticipationBadgesEditorProps } from '@components/participation-badges-editor/participation-badges-editor.interfaces'
import { PlusIcon } from '@heroicons/react/outline'
import { AppendBadgePayload } from '@screens/activity-completion/badges.interfaces'
import { useApiBadges } from '@services/api/badges'
import { useCallback, useMemo, useState } from 'react'

const ParticipationBadgesEditor = ({ appendBadges, id, participationBadges }: ParticipationBadgesEditorProps) => {
  const { data: allBadges } = useApiBadges()

  const [isLoading, setIsLoading] = useState(false)

  // Ajout de nouveaux badges avec la popup BadgeFinder
  // appendBadges() met à jour les states dans la page Badge
  const [showBadgeModal, setShowBadgeModal] = useState(false)

  const participationBadgesWithLevels = useMemo(() => {
    return participationBadges.map(badge => {
      return {
        ...badge,
        badge_levels: allBadges?.results?.find((b) => b.id === badge.id)?.badge_levels ?? []
      }
    })
  }, [allBadges, participationBadges])

  const closeBadgeModal = useCallback((badges: AppendBadgePayload) => {
    setShowBadgeModal(false)
    setIsLoading(true)

    if (badges.length > 0) {
      appendBadges(badges)
    }
    setIsLoading(false)
  }, [id])
  const openBadgeModal = useCallback(() => {
    setShowBadgeModal(true)
  }, [])

  return (
    <>
      <BadgeFinder
        badges={allBadges?.results ?? []}
        currentBadges={participationBadges}
        onRequestClose={closeBadgeModal}
        show={showBadgeModal}
      />

      <div className='flex flex-col gap-4 mt-12'>
        <BadgeCollection
          list={participationBadgesWithLevels}
          name='badges'
        />

        {participationBadges.length === 0 &&
          <div className='p-12 rounded-3xl border border-mono-separator text-center'>Aucun badge attribué</div>
        }

        {isLoading &&
          <FullPageLoading text={'Chargement'} />
        }
      </div>

      <Button
        className='mt-12 bg-white text-blue'
        icon={<PlusIcon />}
        onClick={openBadgeModal}
        theme='secondary'
        type='button'
      >
        Ajouter un badge supplémentaire
      </Button>
    </>
  )
}

export default ParticipationBadgesEditor
