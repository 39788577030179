import { Activities } from '@interfaces/api/activity'
import { axiosGet } from '@services/api/axios'
import { loadOnceSwrConfig } from '@services/api/swr-config'
import qs from 'qs'
import useSwr, { Fetcher } from 'swr'

type Props = Record<string, unknown>

export const useApiActivities = (params: Props) => {
  const queryString = qs.stringify(params)

  const { data, error, mutate } = useSwr<Activities>(
    `/external-app-plugin/activities?${queryString}`,
    axiosGet as Fetcher<Activities>,
    loadOnceSwrConfig
  )

  return {
    data,
    error,
    mutate
  }
}

export const useApiActivitiesType = () => {
  const { data, error, mutate } = useSwr<Activities>(
    '/external-app-plugin/activities/duplicable',
    axiosGet as Fetcher<Activities>,
    loadOnceSwrConfig
  )

  return {
    data,
    error,
    mutate
  }
}
