import { DatetimeInputProps } from '@components/form/datetime-input/datetime-input.interfaces'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const DatetimeInput = ({
  field,
  form: { setFieldValue, setFieldTouched },
  meta,
  ...props
}: DatetimeInputProps) => {
  return <DatePicker
    dateFormat='EEEE dd MMMM yyyy - HH:mm'
    locale='fr'
    onBlur={() => setFieldTouched(field.name)}
    onChange={val => {
      setFieldValue(field.name, val)
    }}
    {...props}
    selected={field.value || null}
    showTimeSelect
  />
}

export default DatetimeInput
