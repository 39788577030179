import ThemeSelectItems from '@components/activity/activity-form/theme-select/theme-select-items'
import { ThemeSelectProps } from '@components/activity/activity-form/theme-select/theme-select.interfaces'
import ImageElement from '@components/image/image-element'
import ImagePlaceholder from '@components/image/image-placeholder'
import { Listbox, Transition } from '@headlessui/react'
import { SelectorIcon } from '@heroicons/react/solid'
import { Theme } from '@interfaces/api/theme'
import { Fragment, useCallback } from 'react'

const ThemeSelect = ({
  field,
  form: { setFieldValue },
  label
}: ThemeSelectProps) => {
  const onSelect = useCallback((theme: Theme) => {
    setFieldValue(field.name, theme)
  }, [setFieldValue, field.name])

  return (
    <Listbox onChange={onSelect} value={field.value}>
      <Listbox.Label className='block text-sm font-medium text-gray-700'>
        {`${label ?? 'Thème'}:`}
      </Listbox.Label>

      <div className='relative mt-1'>
        <Listbox.Button className='relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
          {field.value
            ? (
              <div className='flex items-center'>
                <div className='flex-shrink-0 h-8 w-8 flex items-center justify-center'>
                  <ImageElement
                    className='max-h-full max-w-full'
                    image={field.value.icon}
                    placeholder={<ImagePlaceholder className='h-8 w-8' text={'?'} />}
                    preferredSize={'thumbnail'}
                  />
                </div>

                <div className='ml-4'>
                  <div className='text-sm font-medium text-gray-900'>
                    {field.value.name}
                  </div>
                </div>
              </div>
            )
            : (
              <div className='h-8 flex items-center text-gray-500'>
                {'Cliquez ici pour séléctionner'}
              </div>
            )}

          <span className='ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
            <SelectorIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
            <ThemeSelectItems value={field.value} />
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default ThemeSelect
