import ImageElement from '@components/image/image-element'
import ImagePlaceholder from '@components/image/image-placeholder'
import Modal from '@components/modal'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { BadgeDetailsModalProps } from '@screens/badges/badge-details-modal.interfaces'
import { memo, useRef } from 'react'


const BadgeDetailsModal = ({ badge, onClose }: BadgeDetailsModalProps) => {
  const closeButtonRef = useRef(null)

  return (
    <Modal initialFocus={closeButtonRef} onRequestClose={onClose} show={!!badge}>
      <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6'>
        <button className='absolute top-0 right-0 pt-5 pr-5' onClick={onClose} ref={closeButtonRef}>
          <XIcon className='h-5 w-auto' />
        </button>

        <div className='flex items-center'>
          <ImageElement
            className='max-h-20 w-auto'
            image={badge?.image ?? null}
            placeholder={<ImagePlaceholder className='w-12 h-12' />}
            preferredSize={'thumbnail'}
          />

          <Dialog.Title as='h2' className='text-xl leading-6 ml-3 font-medium text-gray-900'>
            {badge?.name}
          </Dialog.Title>
        </div>

        <div className='mt-3'>
          <p>
            Type :
            {' '}

            {badge?.type}
          </p>

          <p>
            Thème :
            {' '}

            {badge?.theme?.name}
          </p>
        </div>

        <hr className='mt-3' />

        <div className='mt-3'>
          <div className='mb-3'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Description
            </Dialog.Title>

            <p className='text-gray-500'>{badge?.description}</p>
          </div>

          <div>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Critères
            </Dialog.Title>

            <p className='text-gray-500 whitespace-pre'>{badge?.criteria_html}</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default memo(BadgeDetailsModal)
