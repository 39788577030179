import Button from '@components/button/fancy-button'
import CompletionHeader from '@components/completion-header'
import ParticipationToggle from '@components/form/participation-toggle'
import { CSSVariables } from '@interfaces/components/css-variables'
import { RoutesName } from '@navigation/routes-name'
import { ParticipantsFormProps } from '@screens/activity-completion/participants.interface'
import { useApiActivityDetailed } from '@services/api/activities'
import { apiParticipantBadgesUpdate } from '@services/api/participations/participation'
import Notifications from '@services/notifications'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const Participants = () => {
  const offset: CSSVariables = { '--negative-offset': '52px' }

  // Navigation
  const navigate = useNavigate()

  // ID de l'activité
  const { id: idParam } = useParams()
  const id = Number.parseInt(idParam ?? '', 10)

  // Liste des participants
  const { data: activity } = useApiActivityDetailed(id)

  // Form state variables
  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues] = useState<ParticipantsFormProps>()
  useEffect(() => {
    if (activity?.participations) {
      setFormValues(activity.participations.map(participation => ({
        id: participation.id,
        checkbox: participation.status === 'present'
      })))
    }
  }, [activity])

  const onSubmit = async (values: ParticipantsFormProps) => {
    setIsLoading(true)
    const updateStatusRequest: Array<Promise<unknown>> = []
    let atLeastOnePresent = false
    values.forEach(participant => {
      if (participant.checkbox) {
        atLeastOnePresent = true
      }
      const status = participant.checkbox ? 'present' : 'absent'
      updateStatusRequest.push(apiParticipantBadgesUpdate(participant.id, {
        status: status,
        selectedBadges: []
      }))
    })
    Promise.all(updateStatusRequest)
      .catch((error) => {
        Notifications.onError(error)
        throw error
      })
      .finally(() => {
        setIsLoading(false)

        if (!atLeastOnePresent) {
          navigate(RoutesName.createActivityCompletion(id, RoutesName.ACTIVITY_COMPLETION_END))
        } else {
          navigate(RoutesName.createActivityCompletion(id, RoutesName.ACTIVITY_COMPLETION_BADGES))
        }
      })
  }

  return (
    <div style={offset}>
      <CompletionHeader
        backLink={RoutesName.ACTIVITY_COMPLETION_ACTIVITY}
        currentStep={3}
        description='Indiquez quels participants étaient présents lors de l’activité.👇'
        title='Participants'
        totalSteps={4}
      />

      <div className='max-w-container mx-auto -mt-n-offset px-6 md:px-0'>
        <div className='flex flex-col gap-4'>
          {activity?.participations && formValues &&
            <Formik
              initialValues={formValues}
              onSubmit={onSubmit}>
              <Form>
                <div className='flex flex-col gap-4'>
                  {activity.participations.map((participation, index) => (
                    <ParticipationToggle
                      index={index}
                      key={index}
                      participation={participation}
                    />
                  ))}
                </div>

                <Button
                  className='mt-12'
                  isLoading={isLoading}
                  type='submit'
                >
                  Étape suivante
                </Button>
              </Form>
            </Formik>
          }
        </div>
      </div>
    </div>
  )
}

export default Participants
