import TableFooter from '@components/table/table-footer'
import { Badge, Badges } from '@interfaces/api/badge'
import BadgeDetailsModal from '@screens/badges/badge-details-modal'
import BadgeTableHead from '@screens/badges/badge-table-head'
import BadgeTableLoading from '@screens/badges/badge-table-loading'
import BadgeTableRow from '@screens/badges/badge-table-row'
import { useApiBadges } from '@services/api/badges'
import Notifications from '@services/notifications'
import { useCallback, useEffect, useState } from 'react'


const ITEM_BY_PAGE = 10

const BadgeTable = () => {
  // Badges loading parameters
  const [page, setPage] = useState(1)
  const [sortColumn, setSortColumn] = useState('id')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')
  const [selectedBadge, setSelectedBadge] = useState<Badge | null>(null)

  const onLabelClick = useCallback((name: string) => {
    if (name !== sortColumn) {
      setSortColumn(name)
      return
    }

    setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC')
  }, [sortColumn, sortDirection])

  // Loading activities
  const { data, error } = useApiBadges({
    _start: (page - 1) * ITEM_BY_PAGE,
    _limit: ITEM_BY_PAGE,
    _sort: `${sortColumn}:${sortDirection}`
  })

  useEffect(() => {
    if (error) {
      Notifications.onError(error)
    }
  }, [error])

  // Store badges, so we can display something during page or sort change
  const [badges, setBadges] = useState<Badges | null>(null)

  const handleCloseModal = () => {
    setSelectedBadge(null)
  }

  const handleOpenDetail = (badge: Badge) => {
    setSelectedBadge(badge)
  }

  useEffect(() => {
    if (data) {
      setBadges(data)
    }
  }, [data])

  return (
    <>
      <div className='overflow-x-auto'>
        <div className='inline-block min-w-full py-2'>
          <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg'>
            <table className='min-w-full divide-y divide-gray-300'>
              <BadgeTableHead onLabelClick={onLabelClick} />

              <tbody className='bg-white divide-y divide-gray-200'>
                {
                  !data && badges && (
                    <BadgeTableLoading smaller />
                  )
                }

                {
                  badges
                    ? badges?.results?.map((badge) => (
                      <BadgeTableRow badge={badge} key={badge.id} onOpenDetail={handleOpenDetail} />
                    ))
                    : <BadgeTableLoading />
                }
              </tbody>
            </table>

            <TableFooter
              gotoPage={setPage}
              itemPerPage={ITEM_BY_PAGE}
              meta={badges?.meta ?? null}
              page={page}
            />
          </div>
        </div>
      </div>

      <BadgeDetailsModal
        badge={selectedBadge}
        onClose={handleCloseModal}
      />
    </>
  )
}

export default BadgeTable
