import { ActivityFormParticipantPayload } from '@components/activity/activity-form/activity-form-interfaces'
import { ProfileSearchResultProps } from '@components/activity/activity-form/participant-manager/profile-search-result.interfaces'
import ImageElement from '@components/image/image-element'
import ImagePlaceholder from '@components/image/image-placeholder'
import LoadingBox from '@components/loading/loading-box'
import { PlusIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

const ProfileSearchResult = ({ profiles, isLoading, infoMessage, onAddParticipant }: ProfileSearchResultProps) => {
  return (
    <div className='border p-3 rounded-md mt-3 h-96 overflow-y-scroll'>
      {!isLoading && infoMessage && <p className='flex justify-center items-center h-full w-full text-center'>{infoMessage}</p>}

      {isLoading && (
        <div className='flex justify-center items-center h-full w-full'>
          <div className='h-10 w-10'>
            <LoadingBox />
          </div>
        </div>
      )}

      {profiles && profiles.length > 0 && profiles.map((profile, index) => {
        return (
          <div
            className={classNames(
              'flex items-center justify-between text-sm',
              index !== 0 ? 'pt-2' : '',
              index !== profiles.length - 1 ? 'border-b pb-2' : ''
            )}
            key={profile.id}
          >
            <div className='flex items-center'>
              <ImageElement
                className='h-8 w-8 object-cover mr-2 rounded-full'
                image={profile.picture}
                placeholder={<ImagePlaceholder className='h-8 w-8 mr-2 rounded-full' />}
                preferredSize={'thumbnail'}
              />

              <p>
                {profile.first_name}

                {' '}

                {profile.last_name}

                {profile.user.email && <span className='italic text-xs ml-2'>
                  (
                  {profile.user.email}
                  )
                </span>}
              </p>
            </div>

            <button
              className='flex my-2 items-center text-indigo-500 hover:text-indigo-600'
              onClick={() => {
                const participantToAdd: ActivityFormParticipantPayload = {
                  firstName: profile.first_name,
                  lastName: profile.last_name,
                  userId: profile.user.id,
                  picture: profile.picture,
                  email: profile.user.email
                }

                onAddParticipant(participantToAdd)
              }}
              type='button'
            >
              <PlusIcon className='w-4 h-4 mr-1' />
              Ajouter
            </button>
          </div>
        )
      })}
    </div>
  )
}

export default ProfileSearchResult
