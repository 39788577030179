import { SelectErrorProps } from '@components/form/select-error/select-error.interfaces'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

const SelectError = ({ message }: SelectErrorProps) => {
  return (
    <div className='flex items-center space-x-4 p-3'>
      <ExclamationCircleIcon className='flex-shrink-0 h-8 w-auto text-red-600' />

      <span className='text-sm font-medium text-gray-700'>{message}</span>
    </div>
  )
}

export default SelectError
