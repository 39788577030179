import ActivityFormHeader from '@components/activity/activity-form/activity-form-header'
import {
  ActivityFormSchema,
  ActivityFormSchemaAuthor,
  ActivityFormValues
} from '@components/activity/activity-form/activity-form-interfaces'
import { ActivityFormProps } from '@components/activity/activity-form/activity-form.interfaces'
import BadgeCheckboxes from '@components/activity/activity-form/badge-checkboxes'
import CoverManager from '@components/activity/activity-form/cover-manager'
import HelperSelect from '@components/activity/activity-form/helper-select'
import ParticipantManager from '@components/activity/activity-form/participant-manager'
import ThemeSelect from '@components/activity/activity-form/theme-select'
import ZoneSelect from '@components/activity/activity-form/zone-select'
import DatetimeInput from '@components/form/datetime-input'
import FieldRow from '@components/form/field-row/field-row'
import { useUserInfoStore } from '@services/stores/user-info'
import { convertIso8601ToDate } from '@services/utils/date-utils'
import { Field, Form, Formik, FormikConfig } from 'formik'


const ActivityForm = ({ activity, bindFormikProps, isDuplicate = false, onFormSubmit }: ActivityFormProps) => {
  const { userInfo } = useUserInfoStore()

  let initialValues: ActivityFormValues = {
    title: '',
    start_at: '',
    duration: '',
    address: '',
    prerequisite: '',
    about: '',
    status: 'open',
    visibility: 'public',
    participants_limit: '',
    theme: null,
    helper: null,
    zone: null,
    cover: null,
    badges: [],
    participantProfiles: [],
    duplicable: null
  }

  if (activity) {
    initialValues = {
      title: activity.title ?? '',
      start_at: activity.start_at ? convertIso8601ToDate(activity.start_at) : '',
      duration: activity.duration ?? '',
      address: activity.address ?? '',
      prerequisite: activity.prerequisite ?? '',
      about: activity.about ?? '',
      status: activity.status,
      visibility: activity.visibility,
      participants_limit: activity.participants_limit ?? '',
      theme: activity.theme,
      helper: activity.helper,
      zone: activity.zone,
      cover: activity.cover,
      badges: activity.badges ?? [],
      duplicable: activity.duplicable,
      participantProfiles: activity?.participations?.map(participation => ({
        userId: participation.user.id,
        firstName: participation.profile.first_name,
        lastName: participation.profile.last_name,
        picture: participation.profile.picture,
        email: participation.user.email
      })) ?? []
    }
  }

  const onSubmit: FormikConfig<ActivityFormValues>['onSubmit'] = (values, { setSubmitting }) => {
    onFormSubmit(values)
      .finally(() => {
        setSubmitting(false)
      })
  }

  const validationSchema = userInfo?.isAuthor ? ActivityFormSchemaAuthor : ActivityFormSchema

  return (
    <div className='overflow-x-auto'>
      <div className='py-2 align-middle inline-block min-w-full '>
        <div className='shadow border-b border-gray-200 rounded-lg bg-white p-8'>
          {activity && !isDuplicate && (
            <ActivityFormHeader activity={activity} />
          )}

          <div className='mt-3'>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {props => {
                bindFormikProps(props)

                return (
                  <Form>
                    <div className='grid grid-cols-2 gap-x-4 gap-y-4'>

                      <div className='col-span-2'>
                        <FieldRow
                          id='title'
                          label='Titre'
                          name='title'
                        />
                      </div>

                      <FieldRow<Date | ''>
                        component={DatetimeInput}
                        id='start_at'
                        label='Date de début'
                        name='start_at'
                      />

                      <FieldRow<number | ''>
                        id='duration'
                        label='Durée (en heure)'
                        name='duration'
                        type='number'
                      />

                      <div className='col-span-2'>
                        <FieldRow
                          id='address'
                          label='Adresse'
                          name='address'
                        />
                      </div>

                      <FieldRow
                        as='textarea'
                        id='prerequisite'
                        label={'Pré-requis'}
                        name='prerequisite'
                      />

                      <FieldRow
                        as='textarea'
                        id='about'
                        label='A propos'
                        name='about'
                      />

                      <FieldRow
                        as='select'
                        id='status'
                        label='Status'
                        name='status'
                      >
                        <option value='open'>{'Ouvert'}</option>

                        <option value='finished'>{'Clôturé'}</option>
                      </FieldRow>

                      <FieldRow
                        as='select'
                        id='visibility'
                        label='Visibilité'
                        name='visibility'
                      >
                        <option value='public'>{'Publique'}</option>

                        <option value='private'>{'Privé'}</option>
                      </FieldRow>

                      <FieldRow
                        id='participants_limit'
                        label='Participants maximum'
                        name='participants_limit'
                        type='number'
                      />

                      <FieldRow
                        component={ThemeSelect}
                        label='Thème'
                        name='theme'
                      />

                      <FieldRow
                        component={ZoneSelect}
                        label='Zone'
                        name='zone'
                      />

                      {!userInfo?.isAuthor && (
                        <FieldRow
                          component={HelperSelect}
                          label='Animateur'
                          name='helper'
                        />
                      )}

                      <FieldRow
                        component={CoverManager}
                        id='cover'
                        label='Image'
                        name='cover'
                      />

                      <div className='col-span-2'>
                        <FieldRow
                          component={BadgeCheckboxes}
                          id='Badges'
                          label='Badges'
                          name='badges'
                        />
                      </div>

                      {activity?.published_at && <div className='col-span-2'>
                        <Field
                          component={(props) =>
                            <ParticipantManager {...props} activity={activity} />
                          }
                          id='participantProfiles'
                          name='participantProfiles'
                        />
                      </div>}
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivityForm
