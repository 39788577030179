import { ParticipantTableHeadProps } from '@screens/participants/participant-table-head.interfaces'

const ParticipantTableHead = ({ onLabelClick }: ParticipantTableHeadProps) => (
  <thead className='bg-gray-50 text-left text-sm font-semibold text-gray-900'>
    <tr>
      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('id')}
        >
          {'ID'}
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-default font-sans font-semibold'}
        >
          Prénom NOM
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-default font-sans font-semibold'}
        >
          Email
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('created_at')}
        >
          Date de participation
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('activity.id')}
        >
          Activité ID
        </button>
      </th>

      <th className='px-3 py-3.5 relative' scope='col'>
        <span>Actions</span>
      </th>
    </tr>
  </thead>
)

export default ParticipantTableHead
