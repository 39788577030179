import { TableFooterProps } from '@components/table/table-footer.interfaces'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { memo } from 'react'

const TableFooter = ({ itemPerPage, meta, page, gotoPage }: TableFooterProps) => {
  if (!meta) {
    return (
      <div className='bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 h-16'>
        {' '}
      </div>
    )
  }

  const maxPage = Math.max(1, Math.ceil(meta.count / itemPerPage))

  const pages: number[] = []
  const firstPage = Math.max(1, page - 4)
  for (let i = 0; i < 10; i++) {
    if (i + firstPage > maxPage) {
      break
    }
    pages.push(i + firstPage)
  }

  return (
    <div className='bg-white py-3 flex items-center justify-between border-t border-gray-200 px-6'>
      <div className='flex-1 flex items-center justify-between'>
        <div>
          <p className='text-sm text-gray-700'>
            <span className='font-medium'>{meta.count}</span>

            {' résultats'}
          </p>
        </div>

        <div>
          <nav aria-label='Pagination' className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'>
            {page > 1 && (
              <button
                className='relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
                onClick={() => gotoPage(page - 1)}
              >
                <span className='sr-only'>{'Précédent'}</span>

                <ChevronLeftIcon aria-hidden='true' className='h-5 w-5' />
              </button>
            )}

            {pages.map(p => {
              const cls = p === page ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'

              return (
                <button
                  aria-current='page'
                  className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${cls}`}
                  key={p}
                  onClick={() => gotoPage(p)}
                >
                  {p}
                </button>
              )
            })}

            {page < maxPage && (
              <button
                className='relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
                onClick={() => gotoPage(page + 1)}
              >
                <span className='sr-only'>{'Suivant'}</span>

                <ChevronRightIcon aria-hidden='true' className='h-5 w-5' />
              </button>
            )}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default memo(TableFooter)
