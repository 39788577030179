import ProfileSearchResult from '@components/activity/activity-form/participant-manager/profile-search-result'
import { ProfileSearchProps } from '@components/activity/activity-form/participant-manager/profile-search.interfaces'
import { SearchIcon } from '@heroicons/react/outline'
import { Profile } from '@interfaces/api/profile'
import useDebounce from '@root/hooks/use-debounce'
import { useApiProfileSearch } from '@services/api/profile'
import Notifications from '@services/notifications'
import { useEffect, useState } from 'react'

const DEFAULT_INFO_MESSAGE = 'Veuillez entrer au moins 3 caractères pour lancer la recherche'

const ProfileSearch = ({ onAddParticipant }: ProfileSearchProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [foundProfiles, setFoundProfiles] = useState<Profile[] | null>(null)
  const [infoMessage, setInfoMessage] = useState(DEFAULT_INFO_MESSAGE)
  const debouncedSearch = useDebounce<string>(search, 1000)

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 3) {
      setIsLoading(false)
      setInfoMessage(DEFAULT_INFO_MESSAGE)
    } else {
      setInfoMessage('')
      setIsLoading(true)
    }
    setSearch(e.target.value)
    setFoundProfiles(null)
  }

  const { data, error } = useApiProfileSearch({
    _where: {
      _or: [
        { first_name_contains: debouncedSearch },
        { last_name_contains: debouncedSearch },
        { 'user.email_contains': debouncedSearch }
      ]
    }
  }, debouncedSearch?.length < 3)

  useEffect(() => {
    if (error) {
      Notifications.onError(error)
    }
  }, [error])

  useEffect(() => {
    if (data) {
      setFoundProfiles(data)
      setInfoMessage(data.length === 0 ? 'Aucun utilisateur trouvé' : '')
      setIsLoading(false)
    }
  }, [data])


  return (
    <div>
      <p>Rechercher un utilisateur</p>

      <div className='mt-1 flex rounded-md shadow-sm'>
        <div className='relative flex items-stretch flex-grow focus-within:z-10'>
          <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
            <SearchIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />
          </div>

          <input
            className='focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300'
            name='search-user'
            onChange={handleSearchChange}
            placeholder='Rechercher un utilisateur par son nom, prénom ou email'
            type='search'
            value={search}
          />
        </div>
      </div>

      <ProfileSearchResult
        infoMessage={infoMessage}
        isLoading={isLoading}
        onAddParticipant={onAddParticipant}
        profiles={foundProfiles}
      />
    </div>
  )
}

export default ProfileSearch
