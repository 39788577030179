import 'react-toastify/dist/ReactToastify.css'
import Navigation from '@navigation/navigation'
import { ToastContainer } from 'react-toastify'

const Initial = () => {
  return (
    <>
      <Navigation />

      <ToastContainer position='top-center' />
    </>
  )
}

export default Initial
