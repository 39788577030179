import { StepProps } from '@components/progression-steps/step.interfaces'

const Step = ({ isPast, isFirst }: StepProps) => {
  const bgColor = isPast ? 'bg-white' : 'bg-blue-700'
  return (
    <div className='flex items-center'>
      {!isFirst && <div className={`w-4 h-1 ${bgColor}`} />}

      <div className={`w-4 h-4 rounded-full ${bgColor}`} />
    </div>
  )
}

export default Step
