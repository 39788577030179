export enum StrapiRole {
  SUPER_ADMIN_CODE = 'strapi-super-admin',
  EDITOR_CODE = 'strapi-editor',
  AUTHOR_CODE = 'strapi-author'
}

export const STRAPI_ROLE_NAMES: Record<StrapiRole, string> = {
  [StrapiRole.SUPER_ADMIN_CODE]: 'Super Admin',
  [StrapiRole.EDITOR_CODE]: 'Coordinateur',
  [StrapiRole.AUTHOR_CODE]: 'Animateur'
}
