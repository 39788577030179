import { CategoryFilterProps } from '@components/badge-finder/category-filter/category-filter.interfaces'
import { useField } from 'formik'

const CategoryFilter = ({ name, label, iconUrl, iconName, id }: CategoryFilterProps) => {
  const [field] = useField({
    name: name,
    type: 'checkbox',
    value: id
  })
  const { checked } = field
  const toggleStyles = checked ? 'bg-blue hover:bg-blue-700 text-white' : 'bg-beige hover:bg-beige-600 text-mono-black'
  return (
    <label className={`flex items-center min-w-fit px-4 py-2 rounded-full cursor-pointer ${toggleStyles}`}>
      <img alt={iconName} className='w-4 h-4 mr-2' src={iconUrl} />

      <input className='hidden' type='checkbox' {...field} />

      {label}
    </label>
  )
}

export default CategoryFilter
