import { BadgeTableHeadProps } from '@screens/badges/badge-table-head.interfaces'

const BadgeTableHead = ({ onLabelClick }: BadgeTableHeadProps) => (
  <thead className='bg-gray-50 text-left text-sm font-semibold text-gray-900'>
    <tr>
      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('id')}
        >
          {'ID'}
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('name')}
        >
          {'Nom'}
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('theme')}
        >
          {'Thème'}
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('type')}
        >
          {'Type'}
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        {'Publication'}
      </th>

      <th className='px-6 py-3 relative' scope='col'>
        {'Actions'}
      </th>
    </tr>
  </thead>
)

export default BadgeTableHead
