import { ActivityTableHeadProps } from '@screens/activities/activity-table-head.interfaces'

const ActivityTableHead = ({ onLabelClick, showHelper }: ActivityTableHeadProps) => (
  <thead className='bg-gray-50 text-left text-sm font-semibold text-gray-900'>
    <tr>
      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('id')}
        >
          {'ID'}
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('title')}
        >
          {'Titre'}
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('start_at')}
        >
          {'Date'}
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('zone.name')}
        >
          {'Région'}
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('participants_number')}
        >
          {'Participants'}
        </button>
      </th>

      {showHelper && (
        <th className='px-3 py-3.5' scope='col'>
          <button
            className={'cursor-pointer font-sans font-semibold'}
            onClick={() => onLabelClick('helper.name')}
          >
            {'Animateur'}
          </button>
        </th>
      )}

      <th className='px-3 py-3.5' scope='col'>
        <button
          className={'cursor-pointer font-sans font-semibold'}
          onClick={() => onLabelClick('status')}
        >
          {'Status'}
        </button>
      </th>

      <th className='px-3 py-3.5' scope='col'>
        {'Publication'}
      </th>

      <th className='px-6 py-3 relative' scope='col'>
        <span className='sr-only'>Actions</span>
      </th>
    </tr>
  </thead>
)

export default ActivityTableHead
