import { BadgeFavoriteProps } from '@components/activity/activity-form/badge-checkboxes/badge-favorite.interfaces'
import { HeartIcon as HeartIconOutline } from '@heroicons/react/outline'
import { HeartIcon } from '@heroicons/react/solid'
import { AdminBadge } from '@interfaces/api/admin-badge'
import { apiAdminBadgeCreate } from '@services/api/admin-badges/admin-badge-create'
import { apiAdminBadgeDelete } from '@services/api/admin-badges/admin-badge-delete'
import Notifications from '@services/notifications'
import { memo, useState } from 'react'

const BadgeFavorite = ({ badge, adminBadge, onFavorite, onUnFavorite }: BadgeFavoriteProps) => {
  const [operation, setOperation] = useState<'favorite' | 'unFavorite' | null>(null)

  const onClick = () => {
    if (operation !== null) {
      return
    }

    if (adminBadge) {
      setOperation('unFavorite')
      apiAdminBadgeDelete(adminBadge.id)
        .then(() => {
          onUnFavorite(adminBadge)
        })
        .catch((e) => {
          Notifications.onError(e)
        })
        .finally(() => setOperation(null))
    } else {
      setOperation('favorite')
      apiAdminBadgeCreate({ badge: badge.id })
        .then((result: AdminBadge) => {
          onFavorite(result)
        })
        .catch((e) => {
          Notifications.onError(e)
        })
        .finally(() => setOperation(null))
    }
  }

  const isFavorite = operation ? operation === 'favorite' : !!adminBadge

  return (
    <button className='leading-3 h-8' onClick={onClick} type='button'>
      {
        isFavorite
          ? <HeartIcon className='h-5 w-auto text-yellow-400' />
          : <HeartIconOutline className='h-5 w-auto text-gray-600' />
      }
    </button>
  )
}

export default memo(BadgeFavorite)
